import * as ApiBase from "@metamorp/api-base";
import { AddFaq, Faq, FaqItem, GetFaqList, UpdateFaq } from "./types";

const updateFaq = (data: UpdateFaq) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateFaq>({
    method: "PUT",
    path: "/api/v1/faq/update",
    data: data,
  });
};

const getFaqList = (data: GetFaqList) => {
  type Response = ApiBase.Types.ApiListResBase<FaqItem>;
  return ApiBase.request<Response, GetFaqList>({
    method: "POST",
    path: "/api/v1/faq/list",
    data: data,
  });
};

const addFaq = (data: AddFaq) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddFaq>({
    method: "POST",
    path: "/api/v1/faq/add",
    data: data,
  });
};

const getFaq = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<Faq>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/faq/${idx}`,
  });
};

const deleteFaq = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/faq/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/faq/update`
   *
   * Faq를 수정합니다.
   *
   * Update the Faq.
   */
  update: updateFaq,
  /**
   * `POST /api/v1/faq/list`
   *
   * Faq 목록을 조회합니다.
   *
   * Retrieve a list of Faqs.
   */
  getList: getFaqList,
  /**
   * `POST /api/v1/faq/add`
   *
   * Faq를 추가합니다.
   *
   * Add an Faq.
   */
  add: addFaq,
  /**
   * `GET /api/v1/faq/:idx`
   *
   * Faq를 조회합니다.
   *
   * Retrieve an Faq.
   */
  get: getFaq,
  /**
   * `DELETE /api/v1/faq/:idx`
   *
   * Faq를 삭제합니다.
   *
   * Delete an Faq.
   */
  delete: deleteFaq,
};
