export const getColor = (cnt: number) => {
  if (cnt <= 100) return "#72ddf7"; // Light yellow
  if (cnt <= 1000) return "#ffd500"; // Yellow
  if (cnt <= 10000) return "#fb5607"; // Orange
  return "#ff0101"; // Red
};
// temporarily comment to reuse later
// export const calculateFontSize = (days: number) => {
//   if (days <= 14) return 11;
//   if (days <= 30) return 9;
//   return 7; // for 60 days or more
// };

export const calculateChartHeight = (dataLength: number) => {
  if (dataLength <= 14) return 300;

  const rowHeight = 36; // Set each row to 20px
  return dataLength * rowHeight; // Total chart height
};

export const getDate = (value: string) => {
  const date = new Date(value);
  const year = String(date.getFullYear()).slice(-2); // 'yy' 형식의 년도
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 'MM' 형식의 월
  const day = ("0" + date.getDate()).slice(-2); // 'dd' 형식의 일
  return `${year}.${month}.${day}`;
};

export const formatNumber = (value: any) => {
  return typeof value === "number" ? value.toLocaleString() : value;
};
