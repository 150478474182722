export const PARAMS = [
  {
    group: "상세설정",
    params1: [
      {
        label: "스무딩 픽셀",
        placeholder: "스무딩 픽셀을 입력해주세요",
        name: "smoothingPixels",
        unit: "px",
        type: "number",
        min: 0,
        max: 10,
        decimal: 1,
      },
      {
        label: "공차(내부)",
        placeholder: "공차(내부)를 입력해주세요",
        name: "inlineTolerance",
        unit: "mm",
        type: "number",
        min: -3,
        max: 3,
        decimal: 0.1,
      },
      {
        label: "공차(외부)",
        placeholder: "공차(외부)를 입력해주세요",
        name: "outlineTolerance",
        unit: "mm",
        type: "number",
        min: -3,
        max: 3,
        decimal: 0.1,
      },
      {
        label: "안티앨리어싱 사용여부",
        name: "useAntiAliasing",
        type: "boolean",
      },
      {
        label: "바닥 그레이 오프셋 사용여부",
        name: "useBottomGrayOffset",
        type: "boolean",
      },
    ],
    params2: [
      {
        label: "스케일X",
        placeholder: "스케일X를 입력해주세요",
        name: "scaleX",
        unit: "%",
        type: "number",
        min: 10,
        max: 1000,
        decimal: 0.001,
      },
      {
        label: "스케일Y",
        placeholder: "스케일Y를 입력해주세요",
        name: "scaleY",
        unit: "%",
        type: "number",
        min: 10,
        max: 1000,
        decimal: 0.001,
      },
      {
        label: "스케일Z",
        placeholder: "스케일Z를 입력해주세요",
        name: "scaleZ",
        unit: "%",
        type: "number",
        min: 10,
        max: 1000,
        decimal: 0.001,
      },
      {
        label: "바닥 그레이 오프셋",
        placeholder: "바닥 그레이 오프셋을 입력해주세요",
        name: "bottomGrayOffset",
        unit: "mm",
        type: "number",
        min: 0,
        max: 50,
        decimal: 0.1,
      },
      {
        label: "바닥 그레이 레벨",
        placeholder: "바닥 그레이 레벨을 입력해주세요",
        name: "bottomGrayLevel",
        unit: "%",
        type: "number",
        min: 0,
        max: 100,
        decimal: 0.001,
      },
    ],
  },
  {
    group: "조건설정",
    params: [
      {
        label: "바닥 레이어 수",
        placeholder: "바닥 레이어 수를 입력해주세요",
        name: "bottomLayerCount",
        unit: "",
        type: "number",
        min: 0,
        max: 999999999,
        decimal: 1,
      },
      {
        label: "전이 레이어 수",
        placeholder: "전이 레이어 수를 입력해주세요",
        name: "transitionLayerCount",
        unit: "",
        type: "number",
        min: 0,
        max: (values) => values.bottomLayerCount * 2,
        decimal: 1,
      },
      {
        label: "바닥 빛 세기",
        placeholder: "바닥 빛 세기를 입력해주세요",
        name: "bottomLightPower",
        unit: "%",
        type: "number",
        min: 10,
        max: 100,
        decimal: 0.001,
      },
      {
        label: "빛 세기",
        placeholder: "빛 세기를 입력해주세요",
        name: "lightPower",
        unit: "%",
        type: "number",
        min: 10,
        max: 100,
        decimal: 0.001,
      },
    ],
  },
  {
    "group": "시간설정",
    "params": [
      {
        label: "바닥 노출 시간",
        placeholder: "바닥 노출 시간을 입력해주세요",
        name: "bottomExposureTime",
        unit: "sec",
        type: "number",
        min: 0.1,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "노출 시간",
        placeholder: "노출 시간을 입력해주세요",
        name: "exposureTime",
        unit: "sec",
        type: "number",
        min: 0.1,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "대기모드",
        name: "waitingMode",
        type: "select",
      },
    ],
    "resting-time": [
      {
        label: "상승전 대기시간",
        placeholder: "상승전 대기시간을 입력해주세요",
        name: "restTimeBeforeLift",
        unit: "sec",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "상승후 대기시간",
        placeholder: "상승후 대기시간을 입력해주세요",
        name: "restTimeAfterLift",
        unit: "sec",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "하강후 대기시간",
        placeholder: "하강후 대기시간을 입력해주세요",
        name: "restTimeAfterRetract",
        unit: "sec",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
    ],
    "light-off-delay": [
      {
        label: "바닥 노출 후 대기",
        placeholder: "바닥 노출 후 대기를 입력해주세요",
        name: "bottomLightOffDelay",
        unit: "sec",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "노출 후 대기",
        placeholder: "노출 후 대기를 입력해주세요",
        name: "lightOffDelay",
        unit: "sec",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
    ],
  },
  {
    group: "거리설정",
    params: [
      {
        label: "바닥 상승 거리1",
        placeholder: "바닥 상승 거리1을 입력해주세요",
        name: "bottomLiftHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "바닥 상승 거리2",
        placeholder: "바닥 상승 거리2를 입력해주세요",
        name: "bottomLiftHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "바닥 하강 거리1",
        placeholder: "바닥 하강 거리1을 입력해주세요",
        name: "bottomRetractHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "바닥 하강 거리2",
        placeholder: "바닥 하강 거리2를 입력해주세요",
        name: "bottomRetractHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "상승 거리1",
        placeholder: "상승 거리1을 입력해주세요",
        name: "liftHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "상승 거리2",
        placeholder: "상승 거리2를 입력해주세요",
        name: "liftHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "하강 거리1",
        placeholder: "하강 거리1을 입력해주세요",
        name: "retractHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "하강 거리2",
        placeholder: "하강 거리2를 입력해주세요",
        name: "retractHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
    ],
  },
  {
    group: "속도설정",
    params: [
      {
        label: "바닥 상승 속도1",
        placeholder: "바닥 상승 속도1을 입력해주세요",
        name: "bottomLiftSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "바닥 상승 속도2",
        placeholder: "바닥 상승 속도2를 입력해주세요",
        name: "bottomLiftSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },

      {
        label: "바닥 하강 속도1",
        placeholder: "바닥 하강 속도1을 입력해주세요",
        name: "bottomRetractSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "바닥 하강 속도2",
        placeholder: "바닥 하강 속도2를 입력해주세요",
        name: "bottomRetractSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "상승 속도1",
        placeholder: "상승 속도1을 입력해주세요",
        name: "liftSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "상승 속도2",
        placeholder: "상승 속도2를 입력해주세요",
        name: "liftSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "하강 속도1",
        placeholder: "하강 속도1을 입력해주세요",
        name: "retractSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "하강 속도2",
        placeholder: "하강 속도2를 입력해주세요",
        name: "retractSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
    ],
  },
  {
    group: "트랩컨트롤사용여부",
    params1: [
      {
        label: "트랩컨트롤사용여부",
        name: "useTrapControl",
        type: "boolean",
      },
      {
        label: "트랩 상승 거리1",
        placeholder: "트랩 상승 거리1을 입력해주세요",
        name: "trapLiftHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "트랩 상승 거리2",
        placeholder: "트랩 상승 거리2를 입력해주세요",
        name: "trapLiftHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "트랩 하강 거리1",
        placeholder: "트랩 하강 거리1을 입력해주세요",
        name: "trapRetractHeight1",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
      {
        label: "트랩 하강 거리2",
        placeholder: "트랩 하강 거리2를 입력해주세요",
        name: "trapRetractHeight2",
        unit: "mm",
        type: "number",
        min: 0,
        max: 999,
        decimal: 0.1,
      },
    ],
    params2: [
      {
        label: "트랩 상승 속도1",
        placeholder: "트랩 상승 속도1을 입력해주세요",
        name: "trapLiftSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "트랩 상승 속도2",
        placeholder: "트랩 상승 속도2를 입력해주세요",
        name: "trapLiftSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "트랩 하강 속도1",
        placeholder: "트랩 하강 속도1을 입력해주세요",
        name: "trapRetractSpeed1",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
      {
        label: "트랩 하강 속도2",
        placeholder: "트랩 하강 속도2를 입력해주세요",
        name: "trapRetractSpeed2",
        unit: "mm/min",
        type: "number",
        min: 1,
        max: 9999,
        decimal: 0.1,
      },
    ],
  },
];
