import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import CustomCkEditor from "@/pages/EmailTemplate/components/CustomCkEditor";

type TermsConditionsFormProps = {
  isDetailView: boolean;
};

type TermsProps = Api.Request.AddTerms &
  Partial<Pick<Api.Response.Terms, "termsIdx">>;

const DEFAULT_TERMS: TermsProps = {
  titleKo: "",
  titleEn: "",
  titleRu: "",
  contentKo: "",
  contentEn: "",
  contentRu: "",
  displayFlag: 1,
};

const TermsConditionsForm = ({ isDetailView }: TermsConditionsFormProps) => {
  const breadcrumbItems = [
    { title: "Terms Conditions", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();
  const { termsIdx } = useParams();

  const [terms, setTerms] = useState(DEFAULT_TERMS);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [focusedEditors, setFocusedEditors] = useState({
    contentKo: false,
    contentEn: false,
    contentRu: false,
  });

  const getTermsByIdx = useCallback(async () => {
    if (!termsIdx) return;
    try {
      const response = await Api.TermsConditions.get(parseInt(termsIdx, 10));
      const existingTermsConditions = response.data;
      if (existingTermsConditions) {
        const updatedTermsConditions = {
          termsIdx: existingTermsConditions.termsIdx,
          titleKo: existingTermsConditions.titles.ko,
          titleEn: existingTermsConditions.titles.en,
          titleRu: existingTermsConditions.titles.ru,
          contentKo: existingTermsConditions.contents.ko,
          contentEn: existingTermsConditions.contents.en,
          contentRu: existingTermsConditions.contents.ru,
          displayFlag: existingTermsConditions.displayFlag,
        };
        setTerms(updatedTermsConditions);
      }
    } catch (error) {
      console.error("Error fetching Terms & Conditions from API", error);
    }
  }, [termsIdx]);

  useEffect(() => {
    if (isDetailView) {
      getTermsByIdx();
    }
  }, [getTermsByIdx, isDetailView]);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
      checked?: boolean;
    };
  }) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setTerms((prevVer) => ({ ...prevVer, [name]: newValue }));
  };

  const handleSaveTerms = async () => {
    if (
      !terms.titleKo ||
      !terms.titleEn ||
      !terms.titleRu ||
      !terms.contentKo ||
      !terms.contentEn ||
      !terms.contentRu
    ) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }
    setIsLoading(true);

    try {
      let response;
      const requestData = {
        titleKo: terms.titleKo,
        titleEn: terms.titleEn,
        titleRu: terms.titleRu,
        contentKo: terms.contentKo,
        contentEn: terms.contentEn,
        contentRu: terms.contentRu,
        displayFlag: terms.displayFlag,
      };

      if (isDetailView) {
        if (typeof terms?.termsIdx !== "number") return;
        response = await Api.TermsConditions.update({
          ...requestData,
          termsIdx: terms.termsIdx,
        });
      } else {
        response = await Api.TermsConditions.add(requestData);
      }

      const successMessage = getLocalizedMessage(response, "success");
      setIsOpen(false);
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/terms-conditions${location.search}`);
        } else {
          navigate("/terms-conditions");
        }
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error: adding new Terms & Conditions", error);
      } else {
        console.error("Unexpected error", error);
      }
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTerms = async () => {
    if (!terms.termsIdx) return;
    setIsLoading(true);
    try {
      const response = await Api.TermsConditions.delete(terms?.termsIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/terms-conditions");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/terms-conditions${location.search}`);
  };

  const setFocusAs = (editorName: string, focusState: boolean) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: focusState }));
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Terms Conditions">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">약관정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="titleKo"
                    label="제목 한글"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={terms.titleKo || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="titleEn"
                    label="제목 영문"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={terms.titleEn || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="titleRu"
                    label="제목 러시아어"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={terms.titleRu || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>

                <Row>
                  <CustomLabel
                    htmlFor="contentKo"
                    title="내용 한글"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentKo"
                      data={terms.contentKo || ""}
                      isFocused={focusedEditors.contentKo}
                      handleFocus={() => setFocusAs("contentKo", true)}
                      handleBlur={() => setFocusAs("contentKo", false)}
                      onChange={(contentKo: string) =>
                        setTerms((prev) => ({ ...prev, contentKo }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel
                    htmlFor="contentEn"
                    title="내용 영문"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentEn"
                      data={terms.contentEn || ""}
                      isFocused={focusedEditors.contentEn}
                      handleFocus={() => setFocusAs("contentEn", true)}
                      handleBlur={() => setFocusAs("contentEn", false)}
                      onChange={(contentEn: string) =>
                        setTerms((prev) => ({ ...prev, contentEn }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel
                    htmlFor="contentRu"
                    title="내용 러시아어"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentRu"
                      data={terms.contentRu || ""}
                      isFocused={focusedEditors.contentRu}
                      handleFocus={() => setFocusAs("contentRu", true)}
                      handleBlur={() => setFocusAs("contentRu", false)}
                      onChange={(contentRu: string) =>
                        setTerms((prev) => ({ ...prev, contentRu }))
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={terms.displayFlag === 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setTerms({ ...terms, displayFlag: 1 });
                      } else {
                        setTerms({ ...terms, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                </Row>
                <ConfirmModalTypes
                  isLoading={isLoading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveTerms}
                  handleDelete={handleDeleteTerms}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default TermsConditionsForm;
