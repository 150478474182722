import { ChangeEvent, CSSProperties } from "react";
import classNames from "classnames";
import { Col, Label } from "reactstrap";

type ToggleSwitchProps = {
  label: string;
  id: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extraClass?: string;
  style?: CSSProperties;
  colMd?: number;
  isFullWidthLabel?: boolean;
};

const ToggleSwitch = ({
  label,
  id,
  checked,
  onChange,
  disabled = false,
  extraClass = "",
  style = {},
  colMd = 10,
  isFullWidthLabel,
}: ToggleSwitchProps) => {
  return (
    <>
      {label.length > 0 && (
        <Label
          htmlFor={id}
          className={classNames("col-md-2", "col-form-label", {
            ["w-100"]: isFullWidthLabel,
          })}
        >
          {label}
        </Label>
      )}
      <Col md={colMd}>
        <div className={`d-flex align-center ${extraClass}`} style={style}>
          <input
            type="checkbox"
            id={id}
            name={id}
            checked={checked}
            onChange={onChange}
            data-switch="none"
            disabled={disabled}
          />
          <label
            htmlFor={id}
            data-on-label="On"
            data-off-label="Off"
            style={disabled ? { cursor: "initial" } : {}}
          />
        </div>
      </Col>
    </>
  );
};

export default ToggleSwitch;
