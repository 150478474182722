import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";
import { MENU_ITEMS } from "../constants";

const SidebarContent = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("");
  const [expandedMenus, setExpandedMenus] = useState([]);

  useEffect(() => {
    setActiveMenu(location.pathname);
    const currentPath = location.pathname;
    const parentMenu = MENU_ITEMS.find(
      (item) =>
        item.subItems &&
        item.subItems.some((subItem) => subItem.link === currentPath),
    );

    if (parentMenu && !expandedMenus.includes(parentMenu.id)) {
      setExpandedMenus((prev) => [...prev, parentMenu.id]);
    }
  }, [location, i18n.language, expandedMenus]);

  const handleMenuClick = (e, menuId, link, hasSubItems) => {
    if (hasSubItems) {
      e.preventDefault(); // Prevent navigation for items with submenus
      setExpandedMenus((prev) =>
        prev.includes(menuId)
          ? prev.filter((id) => id !== menuId)
          : [...prev, menuId],
      );
    } else {
      setActiveMenu(link);
    }
  };

  const renderMenuItem = (item) => (
    <li
      key={item.id}
      className={expandedMenus.includes(item.id) ? "mm-active" : ""}
    >
      <Link
        to={item.link}
        className={`waves-effect ${activeMenu === item.link ? "active" : ""} ${
          item.subItems ? "has-arrow" : ""
        }`}
        onClick={(e) => handleMenuClick(e, item.id, item.link, !!item.subItems)}
      >
        <i className={item.icon}></i>
        <span className="ms-1">{t(item.label)}</span>
      </Link>
      {item.subItems && (
        <ul
          className={`sub-menu ${
            expandedMenus.includes(item.id) ? "mm-show" : "mm-collapse"
          }`}
        >
          {item.subItems.map((subItem) => (
            <li key={subItem.id}>
              <Link
                to={subItem.link}
                className={activeMenu === subItem.link ? "active" : ""}
                onClick={() => setActiveMenu(subItem.link)}
              >
                {t(subItem.label)}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{t("Menu")}</li>
        {MENU_ITEMS.map(renderMenuItem)}
      </ul>
    </div>
  );
};

export default withRouter(SidebarContent);
