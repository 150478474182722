import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import useEmailTemplateStore from "@/zustandStore/emailTemplateStore";

import CustomCkEditor from "./components/CustomCkEditor";
import PageContainer from "../../components/Common/PageContainer";

type EmailTemplateFormProps = {
  isDetailView: boolean;
};

type EmailTemplateProps = Api.Request.AddEmailTemplate &
  Partial<Pick<Api.Response.EmailTemplate, "tempIdx">>;

const EmailTemplateForm = ({ isDetailView }: EmailTemplateFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tempIdx } = useParams();
  const { loading, setLoading } = useEmailTemplateStore();
  const getLocalizedMessage = useLocalizedMessage();

  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateProps>({
    title: "",
    content: "",
    contentEn: "",
  });

  const [focusedEditors, setFocusedEditors] = useState({
    content: false,
    contentEn: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const setFocusAs = (editorName: string, focusState: boolean) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: focusState }));
  };

  const breadcrumbItems = [
    { title: "Email Template", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const getEmailTemplateByIdx = useCallback(async () => {
    try {
      if (!tempIdx) return;
      const response = await Api.EmailTemplate.get(parseInt(tempIdx));
      const existingEmailTemplate = response.data;
      if (existingEmailTemplate) {
        setEmailTemplate(existingEmailTemplate);
      }
    } catch (error) {
      console.error("Error fetching Email Template from API", error);
    }
  }, [tempIdx]);

  useEffect(() => {
    if (isDetailView) {
      getEmailTemplateByIdx();
    }
  }, [getEmailTemplateByIdx, isDetailView]);

  const handleSaveEmailTemplate = async () => {
    if (
      !emailTemplate.title ||
      !emailTemplate.content ||
      !emailTemplate.contentEn
    ) {
      toast.error("템플릿명과 내용을 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }

    setLoading(true);
    try {
      const requestData = {
        title: emailTemplate.title,
        content: emailTemplate.content,
        contentEn: emailTemplate.contentEn,
      };
      let response;

      if (isDetailView) {
        if (!emailTemplate.tempIdx) return;

        response = await Api.EmailTemplate.update({
          ...requestData,
          tempIdx: emailTemplate.tempIdx,
        });
      } else {
        response = await Api.EmailTemplate.add(requestData);
      }
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-template");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleDeleteEmailTemplate = async () => {
    if (!emailTemplate.tempIdx) return;
    setLoading(true);
    try {
      const response = await Api.EmailTemplate.delete(emailTemplate.tempIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-template");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/email-template${location.search}`);
  };

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    const { name, value } = e.target;
    const newValue = value;

    setEmailTemplate((prevTemp) => ({ ...prevTemp, [name]: newValue }));
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Email Template">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">이메일 템플릿명을 입력해주세요</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="title"
                    label="템플릿명"
                    placeholder="템플릿명을 입력해주세요"
                    type="text"
                    value={emailTemplate.title || ""}
                    onChange={handleChange}
                    initialErrorMessage="템플릿명을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="content"
                    title="내용(한글)"
                    isRequired
                  />
                  <Col md={10}>
                    <CustomCkEditor
                      id="content"
                      data={emailTemplate.content || ""}
                      isFocused={focusedEditors.content}
                      handleFocus={() => setFocusAs("content", true)}
                      handleBlur={() => setFocusAs("content", false)}
                      onChange={(content: string) =>
                        setEmailTemplate((prev) => ({ ...prev, content }))
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="contentEn"
                    title="내용(영문)"
                    isRequired
                  />
                  <Col md={10}>
                    <CustomCkEditor
                      id="contentEn"
                      data={emailTemplate.contentEn || ""}
                      isFocused={focusedEditors.contentEn}
                      handleFocus={() => setFocusAs("contentEn", true)}
                      handleBlur={() => setFocusAs("contentEn", false)}
                      onChange={(contentEn: string) =>
                        setEmailTemplate((prev) => ({ ...prev, contentEn }))
                      }
                    />
                  </Col>
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveEmailTemplate}
                  handleDelete={handleDeleteEmailTemplate}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default EmailTemplateForm;
