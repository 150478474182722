import React from "react";
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import CustomLabel from "@/components/Common/CustomLabel";
import CustomFileInput from "../../../components/Common/CustomFileInput";
import CustomSelector from "../../../components/Common/CustomSelector";
import { CustomModal } from "../../../components/Common/styled";

type SlicingRequestModalProps = {
  title?: string;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  options1: Array<{ label: string; value: number }>;
  selectedOption1: number | null;
  options2: Array<{ label: string; value: number }>;
  selectedOption2: number | null;
  handleSave: () => void;
  fileName: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  allowMultiple?: boolean;
  name?: string;
  mdSize?: string;
  isSelector?: boolean;
};

const SlicingRequestModal = ({
  title,
  isLoading,
  isOpen,
  setIsOpen,
  options1,
  selectedOption1,
  options2,
  selectedOption2,
  handleSave,
  fileName,
  handleChange,
  allowMultiple = false,
  name,
  mdSize,
}: SlicingRequestModalProps) => {
  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          size={mdSize}
          isOpen={isOpen}
          toggle={(prevState: boolean) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader className="mb-2">{title}</ModalHeader>
          <ModalBody>
            <Col md={10}>
              <div className="mb-1">
                <CustomLabel
                  title=" 프린팅 프로파일"
                  htmlFor="printerIdx"
                  isFullWidth
                  isRequired
                />
                <CustomSelector
                  placeholder="Select Printing Profile"
                  name="printingIdx"
                  value={selectedOption1 || ""}
                  options={options1}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <CustomLabel
                  title="서포트 프로파일"
                  htmlFor="supportIdx"
                  isFullWidth
                  isRequired
                />
                <CustomSelector
                  placeholder="Select Support Profile"
                  name="supportIdx"
                  value={selectedOption2 || ""}
                  options={options2}
                  onChange={handleChange}
                />
              </div>
              <CustomFileInput
                name={name}
                allowMultiple={allowMultiple}
                fileName={fileName}
                onChange={handleChange}
              />
            </Col>
          </ModalBody>

          <ModalFooter>
            {isLoading ? (
              <Spinner className="me-2" color="secondary" />
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={handleSave}
                >
                  확인
                </Button>
                <Button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  color="danger"
                  className="waves-effect"
                >
                  취소
                </Button>
              </>
            )}
          </ModalFooter>
        </CustomModal>
      </Col>
    </div>
  );
};

export default SlicingRequestModal;
