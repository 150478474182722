import { create } from "zustand";
import * as Api from "@/api";

type VersionStoreState = {
  versions: Api.Response.VersionItem[];
  loading: boolean;
  error: string | null;
  setVersions: (versions: Api.Response.VersionItem[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useVersionStore = create<VersionStoreState>((set) => ({
  versions: [],
  loading: false,
  error: null,
  setVersions: (versions) => set({ versions }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useVersionStore;
