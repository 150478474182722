import * as ApiBase from "@metamorp/api-base";
import { UsedFunctions } from "./types";
import { DateRange } from "../common/type";

const getFunctionsByUsage = (data: DateRange) => {
  type Response = ApiBase.Types.ApiStandardResBase<UsedFunctions>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/action/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/action/state`
   *
   * @authorization-required
   *
   * Retrieve Functions By Usage data.
   */
  get: getFunctionsByUsage,
};
