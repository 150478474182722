import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import ApiRequest from "../Dashboard/ApiRequest";

const ApiRequestStatus = () => {
  return (
    <StatisticsBase
      title="Api Request Status"
      defaultRange={30}
      columns={[
        { key: "date", name: "Date", label: "일자" },
        { key: "aiCnt", name: "AI-Request", label: "AI 요청수" },
        { key: "apiCnt", name: "API-Request", label: "API 요청수" },
      ]}
      ChartComponent={ApiRequest}
      errorMessage="Failed fetching Api Request Status data"
      onRequestData={Api.ApiRequestStatus.get}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default ApiRequestStatus;
