import { Input, InputGroupText, FormGroup as ReactFormGroup } from "reactstrap";

import styled from "styled-components";

export const FormGroup = styled(ReactFormGroup)`
  margin: 0 !important;
`;

export const SearchInput = styled(Input)`
  height: 30px;
`;

export const CustomInputGroupText = styled(InputGroupText)`
  height: 1.875rem;
  font-size: 0.875rem;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.98);
  }

  &.activeBtn {
    background-color: #4550a8 !important;
  }
`;

export const CustomSelectContainer = styled.div`
  position: relative;

  select:hover {
    cursor: pointer;
  }
`;

export const CustomSelect = styled.select`
  height: 1.875rem;
  cursor: pointer;
  padding: 0.3125rem;
  padding-left: 0.625rem;
`;

export const ArrowDownIcon = styled.i`
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  cursor: pointer;
`;
