import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import RequestByCountry from "../Dashboard/RequestByCountry";

const RequestingCountries = () => (
  <StatisticsBase
    title="Requesting Countries"
    defaultRange={30}
    columns={[
      { key: "country", name: "Country", label: "국가" },
      { key: "cnt", name: "Number of requests", label: "요청수" },
    ]}
    ChartComponent={RequestByCountry}
    errorMessage="Failed fetching Requesting Countries data"
    onRequestData={Api.RequestingCountries.getList}
    getDataForExport={(data) => data || []}
    getDataList={(data) => data || []}
  />
);

export default RequestingCountries;
