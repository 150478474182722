import React, { useCallback, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import PageContainer from "../../components/Common/PageContainer";
import useQueryParams, { useStateRef } from "../../helpers/hooks";
import { OptionProps } from "../AppEquipment";
import { SlicingStatus } from "../Slicing";
import SlicingModal from "../Slicing/components";
import { SlicingStatusCell } from "../Utility/CustomCellsType";

export const editStatusOptions: Array<{
  label: string;
  value: SlicingStatus;
}> = [
  { label: "전체", value: "all" },
  { label: "접수", value: "receipt" },
  { label: "완료", value: "complete" },
  { label: "실패", value: "fail" },
];

const BREADCRUMB_ITEMS = [
  { title: "Edit Request", link: "#" },
  { title: "List", link: "#" },
];

const EditRequest = () => {
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [editRequests, setEditRequests] = useState<Api.Response.EditItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEditRequests, setTotalEditRequests] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [clients, setClients] = useState<OptionProps[]>([]);
  const [selectedFailMessage, setSelectedFailMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<number>(
    parseQueryParam(queryParams.client) ?? -1,
  );
  const [selectedStatus, setSelectedStatus] = useState<SlicingStatus>(
    (queryParams.status as SlicingStatus) ?? editStatusOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      const response = await Api.Common.getClientList();
      const clients = response.data;

      const updatedClients = [
        {
          clientName: "전체",
          clientIdx: -1,
        },
        ...clients,
      ];

      const options = updatedClients.map((client) => ({
        label: client.clientName,
        value: client.clientIdx,
      }));
      setClients(options);
    };
    fetchClients();
  }, []);

  const fetchEditRequests = useCallback(async () => {
    setLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetEditList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        clientIdx: selectedClient,
        status: selectedStatus === "all" ? null : selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Edit.getList(requestData);

      setTotalPage(response.paging.totalPage);
      setTotalEditRequests(response.paging.totalRecord);
      setEditRequests(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching editRequests", error);
      } else {
        console.error("Unexpected error", error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedClient,
    selectedStatus,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchEditRequests();
  }, [fetchEditRequests]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchEditRequests();
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const handleClientSearch = (newClient: number) => {
    setSelectedClient(newClient);
    setCurrentPage(0);
  };

  const handleStatusSearch = (newStatus: SlicingStatus) => {
    setSelectedStatus(newStatus);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedClient(-1);
    setSelectedStatus(editStatusOptions[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (failMessage: string) => {
    setSelectedFailMessage(failMessage);
    setIsModalOpen(true);
  };

  const columns: CustomColumn<Api.Response.EditItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "editIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "요청고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로젝트명",
        accessor: "projectName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "대상명(APP)",
        accessor: "appName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "처리상태",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.EditItem, SlicingStatus>) => (
          <SlicingStatusCell value={value} />
        ),
      },
      {
        Header: "완료보고",
        accessor: "isClientReport",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EditItem>) => {
          const style = value ? { fontWeight: "bold" } : undefined;
          return <span style={style}>{value ? "보고함" : "-"}</span>;
        },
      },
      {
        Header: "처리요청",
        accessor: "isAppRequest",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EditItem>) => {
          const style = value ? { fontWeight: "bold" } : undefined;
          return <span style={style}>{value ? "요청함" : "-"}</span>;
        },
      },
      {
        Header: "실패사유",
        accessor: "failMessage",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EditItem>) => {
          const hasValue = !!value && value.length > 0;
          const style = hasValue
            ? { textDecoration: "underline", cursor: "pointer" }
            : undefined;

          return (
            <span
              style={style}
              onClick={
                hasValue ? () => handleIsModalOpen(value || "") : undefined
              }
            >
              {hasValue ? "보기" : "-"}
            </span>
          );
        },
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Edit Request">
        <ControlPanelTypes
          placeholder="고객사명, 대상명, 등록자명, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={clients}
          option1Type="number"
          selectedOption1={selectedClient}
          setSelectedOption1={handleClientSearch}
          options2={editStatusOptions}
          option2Type="string"
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle1="고객사"
          selectTitle2="유형"
        />
        <CustomTableContainerTypes
          sortByIdx="editIdx"
          columns={columns || []}
          data={editRequests || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalEditRequests}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
      <SlicingModal
        isLoading={loading}
        title="실패사유"
        data={selectedFailMessage}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </React.Fragment>
  );
};

export default EditRequest;
