import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import ConnectionChart from "../Dashboard/ConnectionChart";

const ConnectionStatus = () => {
  return (
    <>
      <StatisticsBase
        title="Connection Status"
        defaultRange={30}
        columns={[
          { key: "date", name: "Date", label: "일자" },
          { key: "pv", name: "PV", label: "PV" },
          { key: "uv", name: "UV", label: "UV" },
        ]}
        ChartComponent={ConnectionChart}
        errorMessage="Failed fetching Api Request Status data"
        onRequestData={Api.ConnectionStatus.get}
        getDataList={(data) => data?.list || []}
        getDataForExport={(data) => data?.list || []}
      />
    </>
  );
};

export default ConnectionStatus;
