import React from "react";
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import CustomFileInput from "./CustomFileInput";
import { CustomModal } from "./styled";

type ModalProps = {
  title?: string;
  subTitle?: string | JSX.Element;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  action?: string;
  handleSave: () => void;
  isFileInput?: boolean;
  fileName: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSingleBtn?: boolean;
  children?: JSX.Element;
  allowMultiple?: boolean;
  name?: string;
  mdSize?: string;
};

const ModalTypes = ({
  title,
  subTitle,
  isLoading,
  isOpen,
  setIsOpen,
  handleSave,
  isFileInput = false,
  fileName,
  handleChange,
  isSingleBtn,
  children,
  allowMultiple,
  name,
  mdSize,
}: ModalProps) => {
  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          size={mdSize}
          isOpen={isOpen}
          toggle={(prevState: boolean) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader className="mb-2">{title}</ModalHeader>
          <ModalBody>
            {subTitle && <p>{subTitle}</p>}
            {isFileInput && (
              <CustomFileInput
                name={name}
                allowMultiple={allowMultiple}
                fileName={fileName}
                onChange={handleChange}
              />
            )}
            {children}
          </ModalBody>

          <ModalFooter>
            {isLoading ? (
              <Spinner className="me-2" color="secondary" />
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={handleSave}
                >
                  확인
                </Button>
                {!isSingleBtn && (
                  <Button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    color="danger"
                    className="waves-effect"
                  >
                    취소
                  </Button>
                )}
              </>
            )}
          </ModalFooter>
        </CustomModal>
      </Col>
    </div>
  );
};

export default ModalTypes;
