import * as ApiBase from "@metamorp/api-base";
import { RegionRanking } from "./type";
import { DateRange } from "../common/type";

const getRequestRegionRanking = (data: DateRange) => {
  type Response = ApiBase.Types.ApiStandardResBase<RegionRanking>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/top/country/state`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/top/country/state`
   *
   * @authorization-required
   *
   * Retrieve Requested Regions Ranking data.
   */
  get: getRequestRegionRanking,
};
