import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import korean from "../../../assets/images/flags/korean.jpeg";
import russia from "../../../assets/images/flags/russia.jpg";
import usFlag from "../../../assets/images/flags/us.jpg";
import i18n from "../../../i18n";

// falgs

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      lng: "Korean",
      flag: korean,
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  changeLanguageAction = (lng) => {
    //set the selected language to i18n
    i18n.changeLanguage(lng);

    if (lng === "ru") this.setState({ lng: "Russian", flag: russia });
    else if (lng === "en") this.setState({ lng: "English", flag: usFlag });
    else if (lng === "ko") this.setState({ lng: "Korean", flag: korean });
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-none d-sm-inline-block"
        >
          <DropdownToggle tag="button" className="btn header-item waves-effect">
            <img
              className=""
              src={this.state.flag}
              alt="Header Language"
              height="16"
            />
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              href=""
              active={this.state.lng === "Russian" ? true : false}
              onClick={() => this.changeLanguageAction("ru")}
              className=" notify-item"
            >
              <img src={russia} alt="user" className="me-1" height="12" />{" "}
              <span className="align-middle">Russian</span>
            </DropdownItem>
            <DropdownItem
              href=""
              active={this.state.lng === "Korean" ? true : false}
              onClick={() => this.changeLanguageAction("ko")}
              className=" notify-item"
            >
              <img src={korean} alt="user" className="me-1" height="12" />{" "}
              <span className="align-middle">Korean</span>
            </DropdownItem>

            <DropdownItem
              active={this.state.lng === "English" ? true : false}
              href=""
              onClick={() => this.changeLanguageAction("en")}
              className="notify-item"
            >
              <img src={usFlag} alt="user" className="me-1" height="12" />{" "}
              <span className="align-middle">English</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withTranslation()(LanguageDropdown);
