import * as ApiBase from "@metamorp/api-base";
import type {
  App,
  AppItem,
  SlicingResultData,
  AddApp,
  UpdateApp,
  GetAppList,
  RequestSlicing,
} from "./types";

const updateApp = (data: UpdateApp) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateApp>({
    method: "PUT",
    path: "/api/v1/app/info/update",
    data: data,
  });
};

const requestSlicing = (data: RequestSlicing) => {
  const formData = new FormData();
  data.files.forEach((file) => {
    formData.append("files", file);
  });

  formData.append("appIdxs", data.appIdxs);
  formData.append("printingIdx", data.printingIdx.toString());
  formData.append("supportIdx", data.supportIdx.toString());

  type Response = ApiBase.Types.ApiStandardResBase<SlicingResultData>;
  return ApiBase.request<Response, FormData>({
    method: "POST",
    path: "/api/v1/app/info/upload/request",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getAppList = (data: GetAppList) => {
  type Response = ApiBase.Types.ApiListResBase<AppItem>;
  return ApiBase.request<Response, GetAppList>({
    method: "POST",
    path: "/api/v1/app/info/list",
    data: data,
  });
};

const addApp = (data: AddApp) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddApp>({
    method: "POST",
    path: "/api/v1/app/info/add",
    data: data,
  });
};

const getApp = (appIdx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<App>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/app/info/${appIdx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/app/info/update`
   *
   * @authorization-required
   *
   * Update an app.
   */
  update: updateApp,
  /**
   * `POST /api/v1/app/info/upload/request`
   *
   * @authorization-required
   *
   * Request slicing.
   */
  requestSlicing: requestSlicing,
  /**
   * `POST /api/v1/app/info/list`
   *
   * @authorization-required
   *
   * Retrieve a list of apps.
   */
  getList: getAppList,
  /**
   * `POST /api/v1/app/info/add`
   *
   * @authorization-required
   *
   * Add an app.
   */
  add: addApp,
  /**
   * `GET /api/v1/app/info/info/:appIdx`
   *
   * @authorization-required
   *
   * Retrieve an app.
   */
  get: getApp,
};
