import React, { useState, useEffect, useCallback } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as Api from "@/api";
import CustomLabel from "@/components/Common/CustomLabel";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import CustomFileInput from "../../components/Common/CustomFileInput.js";
import FormButtons from "../../components/Common/FormButtons";
import ConfirmModal from "../../components/Common/Modal";
import PageContainer from "../../components/Common/PageContainer";
import FormInput from "../../components/Profile/FormInput";
import Selector from "../../components/Profile/Selector";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import useUserStore from "../../zustandStore";
import { CardImgContainer } from "../Utility/styled.js";
import { memberStatusOptions } from "./index.js";

const defaultUser = {
  userId: "",
  userPw: "",
  nickName: "",
  job: "",
  phone: "",
  newsAgree: true,
  adAgree: true,
  file: null,
};

const UserForm = ({ isDetailView }) => {
  const { loading, setLoading } = useUserStore();
  const { userIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Members", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [user, setUser] = useState(defaultUser);
  const [fileName, setFileName] = useState(user.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const filteredStatus = memberStatusOptions.slice(1);

  const getUserByIdx = useCallback(async () => {
    try {
      const response = await Api.User.get(userIdx);
      const existingUser = response.data;
      if (existingUser) {
        setUser(existingUser);
      } else {
        setUser(defaultUser);
      }
    } catch (error) {
      console.error("Error fetching User from API", error);
    }
  }, [userIdx]);

  useEffect(() => {
    if (isDetailView) {
      getUserByIdx();
    }
  }, [getUserByIdx, isDetailView]);

  const handleCancel = () => {
    navigate(`/users${location.search}`);
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (name === "file") {
      setFileName(newValue?.name || "");
    }
    setUser((prevUser) => ({ ...prevUser, [name]: newValue }));
  };

  const openModal = (actionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveUser = async (e) => {
    if (e) e.preventDefault();

    if (
      !user.userId ||
      !user.nickName ||
      !user.job ||
      !user.phone ||
      (!isDetailView && !user.userPw)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      let response;

      if (method === "PUT") {
        response = await Api.User.update({
          userIdx: user.userIdx,
          userId: user.userId,
          userPw: user.userPw,
          nickName: user.nickName,
          phone: user.phone,
          job: user.job,
          stateFlag: user.stateFlag,
          newsAgree: user.newsAgree.toString(),
          adAgree: user.adAgree.toString(),
          file: user.file,
        });
      } else if (method === "POST") {
        response = await Api.User.add({
          userIdx: user.userIdx,
          userId: user.userId,
          userPw: user.userPw,
          nickName: user.nickName,
          phone: user.phone,
          job: user.job,
          newsAgree: user.newsAgree.toString(),
          adAgree: user.adAgree.toString(),
          file: user.file,
        });
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/users${location.search}`);
        } else {
          navigate("/users");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Disable deleting a User
  // const handleDeleteUser = async () => {
  //   setLoading(true);

  //   try {
  //     const response = await Api.User.delete(user.userIdx);

  //     if (response.code === 0) {
  //       toast.success(response.message.ko, {
  //         autoClose: 3000,
  //       });
  //       setTimeout(() => {
  //         navigate("/users");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting user:", error);
  //     toast.error(error.message, {
  //       autoClose: 3000,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Members">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">회원 정보를 입력해주세요</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInput
                    id="userId"
                    label="아이디"
                    placeholder="hello@example.com 이메일형식의 아이디를 입력해주세요"
                    type="email"
                    value={user.userId || ""}
                    onChange={handleChange}
                    errorMessage="아이디를 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInput
                    id="userPw"
                    label="비밀번호"
                    placeholder="비밀번호를 입력해주세요"
                    type="password"
                    value={!isDetailView ? user.userPw : "******"}
                    onChange={handleChange}
                    errorMessage={
                      !isDetailView ? `비밀번호를 입력해주세요` : ""
                    }
                    validate={{ required: { value: !isDetailView } }}
                    isRequired={!isDetailView}
                  />
                </Row>
                <Row>
                  <FormInput
                    id="nickName"
                    label="닉네임"
                    placeholder="닉네임을 입력해주세요"
                    type="text"
                    value={user.nickName || ""}
                    onChange={handleChange}
                    errorMessage="닉네임을 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInput
                    id="job"
                    label="직업"
                    placeholder="직업을 입력해주세요"
                    type="text"
                    value={user.job || ""}
                    onChange={handleChange}
                    errorMessage="직업을 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInput
                    id="phone"
                    label="전화번호"
                    placeholder="전화번호 ex) +82-10-1234-1234"
                    type="text"
                    value={user.phone || ""}
                    onChange={handleChange}
                    errorMessage="전화번호를 입력해주세요"
                    validate={{ required: { value: !isDetailView } }}
                    isRequired
                  />
                </Row>
                {isDetailView && (
                  <Row className="mb-3">
                    <Selector
                      label="추출타입"
                      id="stateFlag"
                      options={filteredStatus}
                      value={user.stateFlag}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                )}
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="customFileInput"
                    title="프로필 이미지"
                  />
                  <CustomFileInput
                    fileName={fileName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !user.file && user.imgPath && (
                  <Row className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        style={{ marginLeft: "4px" }}
                        src={user.imgPath}
                        alt="Profile"
                        className="rounded avatar-lg"
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mb-2">
                  <ToggleSwitch
                    label="뉴스 알림 동의"
                    id="newsAgree"
                    checked={user.newsAgree}
                    onChange={(e) => {
                      setUser((prevUser) => ({
                        ...prevUser,
                        newsAgree: e.target.checked,
                      }));
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <Row className="mb-3">
                  <ToggleSwitch
                    label="광고 알림 동의"
                    id="adAgree"
                    checked={user.adAgree}
                    onChange={(e) => {
                      setUser((prevUser) => ({
                        ...prevUser,
                        adAgree: e.target.checked,
                      }));
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <FormButtons
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton={true}
                />
                <ConfirmModal
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveUser}
                  // handleDelete={handleDeleteUser}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default UserForm;
