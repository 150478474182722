import * as ApiBase from "@metamorp/api-base";
import {
  AddSupportProfile,
  GetSupportProfileList,
  SupportProfile,
  SupportProfileItem,
  UpdateSupportProfile,
} from "./types";

const updateSupportProfile = (data: UpdateSupportProfile) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateSupportProfile>({
    method: "PUT",
    path: "/api/v1/support/profile/update",
    data: data,
  });
};

const getSupportProfileList = (data: GetSupportProfileList) => {
  type Response = ApiBase.Types.ApiListResBase<SupportProfileItem>;
  return ApiBase.request<Response, GetSupportProfileList>({
    method: "POST",
    path: "/api/v1/support/profile/list",
    data: data,
  });
};

const copySupportProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/support/profile/copy/${idx}`,
  });
};

const addSupportProfile = (data: AddSupportProfile) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddSupportProfile>({
    method: "POST",
    path: "/api/v1/support/profile/add",
    data: data,
  });
};

const getSupportProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<SupportProfile>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/support/profile/${idx}`,
  });
};

const deleteSupportProfile = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/support/profile/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/support/profile/update`
   *
   * 서포트 프로파일을 수정합니다.
   *
   * Update support profile.
   */
  update: updateSupportProfile,
  /**
   * `POST /api/v1/support/profile/list`
   *
   * 서포트 프로파일 목록을 조회합니다.
   *
   * Retrieve a list of support profiles.
   */
  getList: getSupportProfileList,
  /**
   * `POST /api/v1/support/profile/copy/:idx`
   *
   * 서포트 프로파일을 복제합니다.
   *
   * Copy support profile.
   */
  copy: copySupportProfile,
  /**
   * `POST /api/v1/support/profile/add`
   *
   * 서포트 프로파일을 추가합니다.
   *
   * Add support profile.
   */
  add: addSupportProfile,
  /**
   * `GET /api/v1/support/profile/:idx`
   *
   * 서포트 프로파일을 조회합니다.
   *
   * Retrieve a support profile.
   */
  get: getSupportProfile,
  /**
   * `DELETE /api/v1/support/profile/:idx`
   *
   * 서포트 프로파일을 삭제합니다.
   *
   * Delete a support profile.
   */
  delete: deleteSupportProfile,
};
