import { create } from "zustand";
import * as Api from "@/api";

type EmailTemplateStoreState = {
  emailTemplates: Api.Response.EmailTemplateItem[];
  loading: boolean;
  error: string | null;
  setEmailTemplates: (emailTemplates: Api.Response.EmailTemplateItem[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useEmailTemplateStore = create<EmailTemplateStoreState>((set) => ({
  emailTemplates: [],
  loading: false,
  error: null,
  setEmailTemplates: (emailTemplates) => set({ emailTemplates }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useEmailTemplateStore;
