import { useRef, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from "./constants";

export const useLocalizedMessage = () => {
  const { i18n } = useTranslation();

  const getLocalizedMessage = (data: any, type: "error" | "success") => {
    let message;

    const defaultMessage =
      type === "error" ? DEFAULT_ERROR_MESSAGE : DEFAULT_SUCCESS_MESSAGE;

    if (data.message) {
      if (typeof data.message === "object") {
        const userLang = i18n.language;
        if (userLang.startsWith("ko")) {
          message = data.message.ko;
        } else if (userLang.startsWith("ru")) {
          message = data.message.ru;
        } else {
          message = data.message.en;
        }
      } else if (typeof data.message === "string") {
        message = data.message;
      }
    }
    return message || defaultMessage;
  };

  return getLocalizedMessage;
};

/**
 * A hook that returns a ref object with the state as its current value.
 *
 * When the state changes, the ref object will be updated with the new state.
 * @param state The state to be defined as a ref object.
 * @returns A ref object with the state as its current value.
 */
export const useStateRef = <T>(state: T) => {
  const ref = useRef(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
};

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const parseQueryParam = (
    value: string | number | (string | null)[] | null,
  ): number | null => {
    if (typeof value === "number") {
      return value;
    } else if (typeof value === "string") {
      return value === "" ? null : parseInt(value, 10);
    } else {
      return null;
    }
  };

  const pageSizeParam = Array.isArray(queryParams.pageSize)
    ? queryParams.pageSize[0]
    : queryParams.pageSize;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam, 10) : 10;

  const pageParam = Array.isArray(queryParams.page)
    ? queryParams.page[0]
    : queryParams.page;
  const page = pageParam ? parseInt(pageParam, 10) : 0;

  const keyword = queryParams.keyword || "";

  const dateRange: [string, string] = useMemo(() => {
    return [
      (queryParams.startDt as string) || "",
      (queryParams.endDt as string) || "",
    ];
  }, [queryParams.startDt, queryParams.endDt]);

  return {
    pageSize,
    page,
    keyword,
    dateRange,
    queryParams,
    parseQueryParam,
  };
};

export default useQueryParams;
