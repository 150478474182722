import * as ApiBase from "@metamorp/api-base";
import {
  CommonApplicationItem,
  CommonClassItem,
  CommonClientItem,
  DefaultMaterialItem,
  DefaultPrinterItem,
  SaveFormatItem,
} from "./types";

const getSaveFormatList = () => {
  type Response = ApiBase.Types.ApiListResBaseWithoutPaging<SaveFormatItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/save/format/list",
  });
};

const getDefaultPrinterList = () => {
  type Response = ApiBase.Types.ApiListResBaseWithoutPaging<DefaultPrinterItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/default/printer/list",
  });
};

const getDefaultMaterialList = () => {
  type Response =
    ApiBase.Types.ApiListResBaseWithoutPaging<DefaultMaterialItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/default/material/list",
  });
};

const getClientList = () => {
  type Response = ApiBase.Types.ApiListResBaseWithoutPaging<CommonClientItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/client/list",
  });
};

const getClassList = () => {
  type Response = ApiBase.Types.ApiListResBaseWithoutPaging<CommonClassItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/class/list",
  });
};

const getApplicationList = () => {
  type Response =
    ApiBase.Types.ApiListResBaseWithoutPaging<CommonApplicationItem>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/common/application/list",
  });
};

export default {
  /**
   * `GET /api/v1/common/save/format/list`
   *
   * 저장 포맷 목록을 조회합니다.
   *
   * Retrieve a list of save formats.
   */
  getSaveFormatList,
  /**
   * `GET /api/v1/common/default/printer/list`
   *
   * 기본 프린터 목록을 조회합니다.
   *
   * Retrieve a list of default printers.
   */
  getDefaultPrinterList,
  /**
   * `GET /api/v1/common/default/material/list`
   *
   * 기본 소재 목록을 조회합니다.
   *
   * Retrieve a list of default materials.
   */
  getDefaultMaterialList,
  /**
   * `GET /api/v1/common/client/list`
   *
   * 고객사 목록을 조회합니다.
   *
   * Retrieve a list of clients.
   */
  getClientList,
  /**
   * `GET /api/v1/common/class/list`
   *
   * 클래스 목록을 조회합니다.
   *
   * Retrieve a list of classes.
   */
  getClassList,
  /**
   * `GET /api/v1/common/application/list`
   *
   * 어플리케이션 목록을 조회합니다.
   *
   * Retrieve a list of applications.
   */
  getApplicationList,
};
