import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import TopRequestModel from "../Dashboard/TopRequestModel";

const RequestModelRanking = () => {
  return (
    <StatisticsBase
      title="Request Model Ranking"
      defaultRange={30}
      columns={[
        { key: "rank", name: "Rank", label: "순위" }, // Adding Rank column
        { key: "classCode", name: "Model", label: "모델" },
        { key: "cnt", name: "Requests", label: "요청수" },
      ]}
      ChartComponent={TopRequestModel}
      errorMessage="Failed fetching Request Model Ranking data"
      onRequestData={Api.RequestModelRanking.get}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default RequestModelRanking;
