import { toast } from "react-toastify";
import * as Api from "@/api";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "@/helpers/constants";

type handleDeletePrinterParams = {
  printerIdx: number;
  navigate: (path: string) => void;
  setLoading: (value: boolean) => void;
  getLocalizedMessage: (message: any, defaultMsg: string) => string;
};

export const handleDeletePrinter = async ({
  printerIdx,
  navigate,
  setLoading,
  getLocalizedMessage,
}: handleDeletePrinterParams) => {
  setLoading(true);

  try {
    const response = await Api.Printer.delete(printerIdx);
    const successMessage = getLocalizedMessage(
      response,
      DEFAULT_SUCCESS_MESSAGE,
    );
    toast.success(successMessage, { autoClose: 3000 });
    setTimeout(() => {
      navigate("/printer");
    }, 1000);
  } catch (error) {
    console.error("Error deleting printer:", error);
    const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
    toast.error(errMessage, {
      autoClose: 3000,
    });
  } finally {
    setLoading(false);
  }
};
