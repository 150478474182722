import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import JoinChart from "../Dashboard/JoinChart";

const RegistrationStatus = () => {
  return (
    <StatisticsBase
      title="Registration Status"
      defaultRange={30}
      columns={[
        { key: "date", name: "Date", label: "일자" },
        { key: "cnt", name: "Quantity", label: "가입수" },
      ]}
      ChartComponent={JoinChart}
      errorMessage="Failed fetching Registration Status data"
      onRequestData={Api.RegistrationStatus.get}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default RegistrationStatus;
