import { Label } from "reactstrap";
import { CustomInput } from "./styled";

type CheckboxInputProps = {
  onHandleChange: (checked: boolean, value: number, name: string) => void;
  value: number;
  id: string;
  label: string;
  checked: boolean;
  name: string;
  disabled?: boolean;
};

const CheckboxInput = ({
  onHandleChange,
  value,
  id,
  label,
  checked,
  name,
  disabled = false,
}: CheckboxInputProps) => {
  return (
    <span className="form-check d-flex align-items-center gap-1 m-1">
      <CustomInput
        type="checkbox"
        id={id}
        name={name}
        className="form-check-input"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e: { target: { checked: boolean } }) =>
          onHandleChange(e.target.checked, value, name)
        }
      />
      <Label className="form-check-label mt-1" for={id}>
        {label}
      </Label>
    </span>
  );
};

export default CheckboxInput;
