import * as ApiBase from "@metamorp/api-base";
import {
  AddEmailTemplate,
  EmailTemplate,
  EmailTemplateItem,
  EmailTemplateMain,
  GetTemplateList,
  UpdateEmailTemplate,
} from "./types";

const updateEmailTemplate = (data: UpdateEmailTemplate) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateEmailTemplate>({
    method: "PUT",
    path: "/api/v1/email/template/update",
    data: data,
  });
};

const getEmailTemplateList = (data: GetTemplateList) => {
  type Response = ApiBase.Types.ApiListResBase<EmailTemplateItem>;
  return ApiBase.request<Response, GetTemplateList>({
    method: "POST",
    path: "/api/v1/email/template/list",
    data: data,
  });
};

const addEmailTemplate = (data: AddEmailTemplate) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddEmailTemplate>({
    method: "POST",
    path: "/api/v1/email/template/add",
    data: data,
  });
};

const getEmailTemplate = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<EmailTemplate>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/email/template/${idx}`,
  });
};

const deleteEmailTemplate = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/email/template/${idx}`,
  });
};

const getAllEmailTemplates = () => {
  type Response = ApiBase.Types.ApiListResBaseWithoutPaging<EmailTemplateMain>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/email/template/list/all",
  });
};

export default {
  /**
   * `PUT /api/v1/email/template/update`
   *
   * 이메일템플릿을 수정합니다.
   *
   * Update an Email Template.
   */
  update: updateEmailTemplate,
  /**
   * `POST /api/v1/email/template/list`
   *
   * 이메일템플릿 목록을 조회합니다.
   *
   * Retrieve a list of Email Templates.
   */
  getList: getEmailTemplateList,
  /**
   * `POST /api/v1/email/template/add`
   *
   * 이메일템플릿을 추가합니다.
   *
   * Add an Email Template.
   */
  add: addEmailTemplate,
  /**
   * `GET /api/v1/email/template/:idx`
   *
   * 이메일템플릿을 조회합니다.
   *
   * Retrieve an Email Template.
   */
  get: getEmailTemplate,
  /**
   * `DELETE /api/v1/email/template/:idx`
   *
   * 이메일템플릿을 삭제합니다.
   *
   * Delete an Email Template.
   */
  delete: deleteEmailTemplate,
  /**
   * `GET /api/v1/email/template/list/all`
   *
   * 모든 이메일템플릿을 조회합니다.
   *
   * Retrieve All Email Templates.
   */
  getAll: getAllEmailTemplates,
};
