import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import UsageByFunction from "../Dashboard/UsageByFunction";

const UsageByFunctionStatus = () => {
  return (
    <StatisticsBase
      title="Usage By Function"
      defaultRange={30}
      ChartComponent={UsageByFunction}
      errorMessage="Failed fetching Usage By Function data"
      onRequestData={Api.UsageByFunction.get}
      getDataList={(data) => data?.list || []}
      showTable={false}
    />
  );
};

export default UsageByFunctionStatus;
