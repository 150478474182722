import * as ApiBase from "@metamorp/api-base";

// Set the endpoint to Backoffice
ApiBase.environment.setEndpoint("Backoffice");

/**
 * API 실행 환경에 따른 엔드포인트 값
 *
 * Endpoints based on API execution environment
 */
export const ENDPOINT = {
  DEVELOPMENT: ApiBase.environment.Development.Backoffice,
  PRODUCTION: ApiBase.environment.Production.Backoffice,
};

export const {
  checkIsDevelopment,
  checkIsProduction,
  getEndpoint,
  getEnvironment,
  getAccessToken,
  setEnvironment,
  setAccessToken,
  initialize,
} = ApiBase.environment;
