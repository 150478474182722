import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import CategoryRow, { CategoryRowCategory } from "./components";
import PageContainer from "../../components/Common/PageContainer";

const BREADCRUMB_ITEMS = [
  { title: "Notice Management", link: "#" },
  { title: "Category Management", link: "#" },
];

const Categories = () => {
  const getLocalizedMessage = useLocalizedMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<Api.Request.UpdateCategory[]>(
    [],
  );
  const [newCategory, setNewCategory] = useState({
    orderNum: 0,
    displayFlag: 0,
    nameKo: "",
    nameEn: "",
    nameRu: "",
  });
  const [selectedCategory, setSelectedCategory] =
    useState<Api.Request.UpdateCategory | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const openModal = (
    actionType: ActionType,
    category?: Api.Request.UpdateCategory,
  ) => {
    setAction(actionType);
    setSelectedCategory(category || null);
    setIsOpen(true);
  };

  const getCategoryList = async () => {
    setIsLoading(true);

    try {
      const response = await Api.Category.getList();
      const transformedData = response.data.map((item) => ({
        cateIdx: item.cateIdx,
        orderNum: item.orderNum,
        displayFlag: item.displayFlag,
        nameKo: item.names.ko,
        nameEn: item.names.en,
        nameRu: item.names.ru,
      }));
      setCategories(transformedData);
    } catch (error) {
      console.error("Failed fetching Categories List");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const handleNewCategoryChange = (
    updatedCategory: Api.Request.AddCategory,
  ) => {
    setNewCategory(updatedCategory);
  };

  const handleCategoryChange = (updatedCategory: CategoryRowCategory) => {
    if ("cateIdx" in updatedCategory) {
      setCategories((prev) =>
        prev.map((cat) =>
          cat.cateIdx === updatedCategory.cateIdx ? updatedCategory : cat,
        ),
      );
    }
  };

  const handleSaveCategory = async () => {
    if (
      action === "save" &&
      (!newCategory.orderNum ||
        !newCategory.nameKo ||
        !newCategory.nameEn ||
        !newCategory.nameRu)
    ) {
      toast.error("카테고리 모든 필드 입력해주세요", { autoClose: 3000 });
      setIsOpen(false);
      return;
    }
    setIsLoading(true);

    try {
      let response;
      if (action === "save") {
        response = await Api.Category.add(newCategory);
        setNewCategory({
          orderNum: 0,
          displayFlag: 0,
          nameKo: "",
          nameEn: "",
          nameRu: "",
        });
      } else if (action === "edit" && selectedCategory) {
        response = await Api.Category.update(selectedCategory);
      }

      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      getCategoryList();
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedCategory) return;

    setIsLoading(true);
    try {
      const response = await Api.Category.delete(selectedCategory.cateIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, { autoClose: 3000 });
      getCategoryList();
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Category Management"
    >
      <Row>
        <Col className="col-12">
          <div className="card card-body">
            <div className="table-responsive">
              <table
                className="table table-hover mb-0 text-center align-middle"
                style={{ minWidth: "33.125rem" }}
              >
                <thead className="table-light">
                  <tr>
                    <th>No</th>
                    <th>카테고리명</th>
                    <th>영문명</th>
                    <th>러시아명</th>
                    <th>표시순번</th>
                    <th>표시여부</th>
                    <th>선택</th>
                  </tr>
                </thead>
                <tbody>
                  <CategoryRow
                    category={newCategory}
                    isEditMode={false}
                    onChange={handleNewCategoryChange}
                    openModal={() => openModal("save")}
                  />
                  {isLoading ? (
                    <tr
                      style={{
                        position: "absolute",
                        top: "90%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      <td>
                        <Spinner className="me-2" color="secondary" />
                      </td>
                    </tr>
                  ) : (
                    categories.map((category) => (
                      <CategoryRow
                        key={category.cateIdx}
                        category={category}
                        isEditMode={true}
                        onChange={handleCategoryChange}
                        openModal={(action) => openModal(action, category)}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
        <ConfirmModalTypes
          isLoading={isLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          action={action}
          handleSave={handleSaveCategory}
          handleDelete={handleDelete}
        />
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default Categories;
