import React, { useCallback, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { toast, ToastContainer } from "react-toastify";
import * as Api from "@/api";
import ConfirmModalTypes from "@/components/Common/ConfirmModalTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import useQueryParams, {
  useLocalizedMessage,
  useStateRef,
} from "@/helpers/hooks";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import PageContainer from "../../components/Common/PageContainer";
import { ClickableCell, EmailStatusCell } from "../Utility/CustomCellsType";

export const SENDING_STATUS_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "대기중", value: 0 },
  { label: "성공", value: 1 },
  { label: "실패", value: 2 },
  { label: "취소", value: 3 },
];

const BREADCRUMB_ITEMS = [
  { title: "Email History", link: "#" },
  { title: "List", link: "#" },
];

const EmailList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();

  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEmails, setTotalEmails] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedStatus, setSelectedStatus] = useState<number>(
    parseQueryParam(queryParams.status) ?? SENDING_STATUS_OPTIONS[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [emails, setEmails] = useState<Api.Response.EmailItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmailIds, setSelectedEmailIds] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleRowClick = useCallback(
    (item: { histIdx: number }) => {
      navigate(`/email-history/${item.histIdx}${window.location.search}`);
    },
    [navigate],
  );

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      status: selectedStatus,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedStatus,
    location.pathname,
    navigate,
  ]);

  const fetchEmails = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetEmailList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        status: selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Emailing.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalEmails(response.paging.totalRecord);
      setEmails(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching emails", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedStatus,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  const handleOpenModal = () => {
    if (selectedEmailIds.length === 0) {
      toast.error("먼저 대기중인 이메일을 선택해주세요", {
        autoClose: 3000,
      });
      return;
    } else {
      setIsOpen(true);
    }
  };

  const handleCancelEmail = async () => {
    setIsLoading(true);

    try {
      const requestData = {
        idx: selectedEmailIds.join(","),
      };

      const response = await Api.Emailing.cancel(requestData);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      fetchEmails();
      setSelectedEmailIds([]);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const handleCheckboxChange = useCallback((histIdx: number) => {
    setSelectedEmailIds((prev) =>
      prev.includes(histIdx)
        ? prev.filter((id) => id !== histIdx)
        : [...prev, histIdx],
    );
  }, []);

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedEmailIds(
          emails
            .filter((email) => email.status === 0)
            .map((email) => email.histIdx),
        );
      } else {
        setSelectedEmailIds([]);
      }
    },
    [emails],
  );

  const handleSearch = () => {
    setCurrentPage(0);
    fetchEmails();
  };

  const handleStatusSearch = (newStatus: number | string) => {
    setSelectedStatus(newStatus as number);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(SENDING_STATUS_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.EmailItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "histIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "제목",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이메일",
        accessor: "targetEmail",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.EmailItem>) => (
          <ClickableCell
            value={value as string}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "전송상태",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EmailItem>) => (
          <EmailStatusCell value={value} />
        ),
      },
      {
        Header: "실패사유",
        accessor: "failMessage",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.EmailItem>) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Email History">
        <ControlPanelTypes
          placeholder="제목,이메일주소,등록자,수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options2={SENDING_STATUS_OPTIONS}
          option2Type="number"
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle2="전송상태"
          isSingleSelect={true}
        />
        <CustomTableContainerTypes
          btnTitle="취소요청"
          btnIcon={<i className="ri-close-line me-2 align-middle"></i>}
          sortByIdx="histIdx"
          handleAddItem={handleOpenModal}
          columns={columns || []}
          data={emails || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalEmails}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          selectedIds={selectedEmailIds}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckAll={handleCheckAll}
          showCheckboxColumn={true}
          filterCondition={(item) => item.status === 0} // Custom filter logic for EmailList
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <ConfirmModalTypes
          isLoading={isLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          action="cancel"
          handleSave={handleCancelEmail}
        />
        <ToastContainer />
      </PageContainer>
    </React.Fragment>
  );
};

export default EmailList;
