import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import ControlPanelTypes from "@/components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { DisplayCell } from "@/pages/Utility/CustomCells";
import { ClickableCell } from "@/pages/Utility/CustomCellsType";
import { SHOW_OPTIONS } from "@/pages/Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Terms Conditions", link: "#" },
  { title: "List", link: "#" },
];

const TermsConditions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [termsConditions, setTermsConditions] = useState<
    Api.Response.TermsItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalTermsConditions, setTotalTermsConditions] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedShow, setSelectedShow] = useState(() => {
    const parsedShow = parseQueryParam(queryParams.show);
    return parsedShow !== null ? parsedShow : SHOW_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { termsIdx: number }) => {
      navigate(`/terms-conditions/${item.termsIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddTerms = () => {
    navigate(`/terms-conditions/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchTermsConditions = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        displayFlag: selectedShow,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.TermsConditions.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalTermsConditions(response.paging.totalRecord);
      setTermsConditions(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching Terms & Conditions list", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedShow,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchTermsConditions();
  }, [fetchTermsConditions]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchTermsConditions();
  };

  const handleShowSearch = (newShow: number | string) => {
    setCurrentPage(0);
    setSelectedShow(newShow as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.TermsItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "termsIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "제목",
        accessor: (row) => row.titles.ko,
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.TermsItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.TermsItem>) => (
          <DisplayCell value={value} />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer
        title="Terms Conditions"
        breadcrumbItems={BREADCRUMB_ITEMS}
      >
        <ControlPanelTypes
          placeholder="제목, 등록자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options2={SHOW_OPTIONS}
          option2Type="number"
          selectedOption2={selectedShow}
          setSelectedOption2={handleShowSearch}
          selectTitle2="표시 여부"
          isSingleSelect={true}
        />
        <CustomTableContainerTypes
          btnTitle="약관등록"
          sortByIdx="termsIdx"
          handleAddItem={handleAddTerms}
          isLoading={isLoading}
          columns={columns || []}
          data={termsConditions || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalTermsConditions}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default TermsConditions;
