export const REGISTRATION_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "사용자등록", value: 0 },
  { label: "기본", value: 1 },
];

export const SHOW_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "숨김", value: 0 },
  { label: "표시", value: 1 },
];

export const STATUS_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "미처리", value: 0 },
  { label: "처리완료", value: 1 },
];
