import * as Api from "@/api";
import StatisticsBase from "./StatisticsBase";
import ActiveUserRate from "../Dashboard/ActiveUserRate";

const ActiveUserRateStatus = () => {
  return (
    <StatisticsBase
      title="Active User Rate"
      defaultRange={30}
      columns={[
        { key: "date", name: "Date", label: "일자" },
        { key: "activeRate", name: "App Activity(%)", label: "앱활성율" },
        { key: "connectedCnt", name: "Visitors", label: "방문자수" },
      ]}
      ChartComponent={ActiveUserRate}
      errorMessage="Failed fetching Active User Rate data"
      onRequestData={Api.ActiveUserRate.get}
      getDataList={(data) => data?.list || []}
      getDataForExport={(data) => data?.list || []}
    />
  );
};

export default ActiveUserRateStatus;
