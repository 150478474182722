import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import * as Api from "@/api";
import DeleteModal from "@/components/Common/DeleteModal";
import { useLocalizedMessage, useStateRef } from "@/helpers/hooks";
import { handleDeleteMaterial } from "./utils";
import ControlPanel from "../../components/Common/ControlPanel";
import CustomTableContainer from "../../components/Common/CustomTableContainer";
import PageContainer from "../../components/Common/PageContainer";
import useMaterialStore from "../../zustandStore/materialStore";
import {
  ClickableCell,
  DisplayCell,
  ImageCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Material Management", link: "#" },
  { title: "List", link: "#" },
];

const Material = () => {
  const { materials, setMaterials, loading, setLoading, setError } =
    useMaterialStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();
  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalMaterials, setTotalMaterials] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const searchKeywordRef = useStateRef(searchKeyword);
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [selectedMaterial, setSelectedMaterial] = useState(
    queryParams.material || REGISTRATION_OPTIONS[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || SHOW_OPTIONS[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/material/${item.materialIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddMaterial = () => {
    navigate(`/material/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      material: selectedMaterial,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    dateRange,
    searchKeyword,
    selectedMaterial,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchMaterials = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedMaterial,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Material.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalMaterials(response.paging.totalRecord);
      setMaterials(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedMaterial,
    selectedShow,
    dateRange[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchMaterials();
  }, [fetchMaterials]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchMaterials();
  };

  const handleMaterialSearch = (newMaterial) => {
    setCurrentPage(0);
    setSelectedMaterial(newMaterial);
  };

  const handleShowSearch = (newShow) => {
    setCurrentPage(0);
    setSelectedShow(newShow);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedMaterial(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (item) => {
    setMaterialToDelete(item);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    const materialIdx = materialToDelete.materialIdx;
    await handleDeleteMaterial({
      materialIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsModalOpen(false);
    fetchMaterials();
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "materialIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => (
          <ImageCell value={value} type="material" />
        ),
      },
      {
        Header: "소재명",
        accessor: "materialName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "제조사명",
        accessor: "manufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본소재여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <RegTypeCell value={value} />,
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DisplayCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbItems={BREADCRUMB_ITEMS}
        title="Material Management"
      >
        <ControlPanel
          placeholder={
            "소재명, 제조사명, 등록자, 수정자명 등으로 검색해주세요."
          }
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={dateRange}
          setDateRange={handleDateSearch}
          options1={REGISTRATION_OPTIONS}
          options2={SHOW_OPTIONS}
          selectedOption1={selectedMaterial}
          setSelectedOption1={handleMaterialSearch}
          selectedOption2={selectedShow}
          setSelectedOption2={handleShowSearch}
          selectTitle1="기본소재여부"
          selectTitle2="표시여부"
        />
        <CustomTableContainer
          sortByIdx="materialIdx"
          btnTitle="소재 등록"
          handleAddItem={handleAddMaterial}
          handleRowClick={handleRowClick}
          columns={columns || []}
          data={materials || []}
          isPagination={true}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalMaterials}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <DeleteModal
          isLoading={loading}
          data={materialToDelete?.materialName}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onHandleDelete={handleDelete}
        />
        <ToastContainer />
      </PageContainer>
    </React.Fragment>
  );
};

export default Material;
