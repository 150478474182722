import { ReactNode, useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { formatNumber } from "./utils";

type TopRequestModelProps = {
  data: Api.Response.TopRequestModel | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isFullWidth?: boolean;
  children?: ReactNode;
};

const TopRequestModel = ({
  data,
  selectedDays,
  onDaysSelection,
  isHeader,
  children,
}: TopRequestModelProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current || !data || !data.cntList || !data.classCodeList)
      return;

    const chartOptions = {
      series: [
        {
          name: "요청수",
          data: data?.cntList,
        },
      ],
      chart: {
        height: 290,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#5c67f7",
        "#e354d4",
        "#ff8e6f",
        "#0ca3e7",
        "#fe5454",
        "#0cd7b1",
        "#7b76fe",
        "#fe7c58",
        "#10b818",
        "#fff96c",
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          columnWidth: "35%",
          borderRadius: "4",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        strokeDashArray: 3,
      },
      xaxis: {
        categories: data?.classCodeList,
        labels: {
          style: {
            colors: [
              "#5c67f7",
              "#e354d4",
              "#ff8e6f",
              "#0ca3e7",
              "#fe5454",
              "#0cd7b1",
              "#7b76fe",
              "#fe7c58",
            ],
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: formatNumber,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
    };

    // If chart instance exists, update it instead of creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.updateOptions(chartOptions, true, true);
      chartInstanceRef.current.updateSeries([{ data: data.cntList }], true);
    } else {
      // Create new chart instance if it doesn't exist
      chartInstanceRef.current = new ApexCharts(chartRef.current, chartOptions);
      chartInstanceRef.current.render();
    }
  }, [data]);

  return (
    <ChartBase
      title="TOP10 요청모델"
      tooltipId="reqModelInfo"
      tooltipContent="선택기간동안 사용된 상위10개 모델입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[30, 60, 90]}
      redirectUrl="/request-model-ranking"
      minHeight="20.625rem"
      isHeader={isHeader}
    >
      <div ref={chartRef}></div>
      {children}
    </ChartBase>
  );
};

export default TopRequestModel;
