import { Button, FormGroup } from "reactstrap";
import { ActionType } from "./ConfirmModalTypes";

interface FormButtonsProps {
  isDetailView?: boolean;
  openModal: (actionType: ActionType) => void;
  handleCancel: () => void;
  showCopyButton?: boolean;
  hideDeleteButton?: boolean;
  showDownloadButton?: boolean;
  handleDownload?: () => void;
  downloadFileName?: string;
  isSendingEmail?: boolean;
}

const FormButtonsTypes = ({
  isDetailView = false,
  openModal,
  handleCancel,
  showCopyButton = false,
  hideDeleteButton,
  showDownloadButton = false,
  handleDownload,
  downloadFileName,
  isSendingEmail = false,
}: FormButtonsProps) => (
  <FormGroup>
    <div className="d-flex justify-content-end">
      {isDetailView && showDownloadButton && (
        <Button
          type="button"
          color="info"
          className="me-2"
          onClick={handleDownload}
        >
          {downloadFileName} 다운로드
        </Button>
      )}
      {!isSendingEmail && (
        <Button
          type="button"
          color="primary"
          className="me-2"
          onClick={() => openModal(isDetailView ? "edit" : "save")}
        >
          {isDetailView ? "수정" : "저장"}
        </Button>
      )}
      {isSendingEmail && (
        <Button
          type="button"
          color="primary"
          className="me-2"
          onClick={() => openModal("send")}
        >
          전송
        </Button>
      )}
      {isDetailView && showCopyButton && (
        <Button
          type="button"
          color="success"
          className="me-2"
          onClick={() => openModal("copy")}
        >
          복사
        </Button>
      )}
      {isDetailView && !hideDeleteButton && (
        <Button
          type="button"
          color="danger"
          className="me-2"
          onClick={() => openModal("delete")}
        >
          삭제
        </Button>
      )}
      <Button type="reset" color="secondary" onClick={handleCancel}>
        취소
      </Button>
    </div>
  </FormGroup>
);

export default FormButtonsTypes;
