import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "../../components/Common/ConfirmModalTypes";
import FormButtonsTypes from "../../components/Common/FormButtonsTypes";
import PageContainer from "../../components/Common/PageContainer";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import Selector from "../../components/Profile/Selector";
import { useLocalizedMessage } from "../../helpers/hooks";
import useAppEquipmentStore from "../../zustandStore/appEquipmentStore";
import { stateOptions } from "../Customers";
import { ClientProps } from "./index";

type AppEquipmentFormProps = {
  isDetailView: boolean;
};

export type AppEquipment = Api.Request.AddApp &
  Partial<Pick<Api.Response.App, "appIdx" | "registrationId">>;

const defaultAppEquipment: AppEquipment = {
  appId: "",
  appPw: "",
  appName: "",
  clientIdx: 0,
  stateFlag: 0,
};

const pureStateOptions = stateOptions.slice(1);

const AppEquipmentForm = ({ isDetailView }: AppEquipmentFormProps) => {
  const { appIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, setLoading } = useAppEquipmentStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "App Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [appEquipment, setAppEquipment] =
    useState<AppEquipment>(defaultAppEquipment);

  const [clients, setClients] = useState<ClientProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const getAppEquipmentByIdx = useCallback(async () => {
    try {
      if (!appIdx) return;
      const response = await Api.App.get(parseInt(appIdx));

      const existingAppEquipment = response.data;
      if (existingAppEquipment) {
        setAppEquipment((prev) => ({
          ...prev,
          ...existingAppEquipment,
        }));
      } else {
        setAppEquipment(defaultAppEquipment);
      }
    } catch (error) {
      console.error("Error fetching app-equipment from API", error);
    }
  }, [appIdx]);

  useEffect(() => {
    if (isDetailView) {
      getAppEquipmentByIdx();
    }
  }, [getAppEquipmentByIdx, isDetailView]);

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type: string;
      checked: boolean;
      files: FileList | null;
    };
  }) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;

    setAppEquipment((prevAppEquip) => ({ ...prevAppEquip, [name]: newValue }));
  };

  useEffect(() => {
    const getClients = async () => {
      const response = await Api.Common.getClientList();
      const clients: ClientProps[] = response?.data;
      if (clients.length === 0) return;

      setClients(clients);
      setAppEquipment((prev) => ({ ...prev, clientIdx: clients[0].clientIdx }));
    };

    getClients();
  }, []);

  const handleSaveAppEquipment = async () => {
    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    if (
      (!isDetailView && !appEquipment.appId) ||
      (!isDetailView && !appEquipment.appPw) ||
      !appEquipment.appName
    ) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    try {
      let response;

      if (method === "PUT") {
        if (typeof appEquipment?.appIdx !== "number") return;

        const updateData: Api.Request.UpdateApp = {
          appIdx: appEquipment.appIdx,
          appPw: appEquipment.appPw,
          appName: appEquipment.appName,
          clientIdx: appEquipment.clientIdx,
          stateFlag: appEquipment.stateFlag,
        };
        response = await Api.App.update(updateData);
      } else if (method === "POST") {
        const createData: Api.Request.AddApp = {
          appId: appEquipment.appId,
          appPw: appEquipment.appPw || "",
          appName: appEquipment.appName,
          clientIdx: appEquipment.clientIdx,
          stateFlag: appEquipment.stateFlag,
        };

        response = await Api.App.add(createData);
      }

      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/app-equipment${location.search}`);
        } else {
          navigate("/app-equipment");
        }
      }, 1000);
    } catch (error: any) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    navigate(`/app-equipment${location.search}`);
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="App Management">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">APP(장비)정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="appId"
                    label="아이디"
                    placeholder="hello@example.com 이메일 형태로 입력해주세요"
                    type="text"
                    value={appEquipment.appId || ""}
                    onChange={handleChange}
                    initialErrorMessage="아이디를 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired={!isDetailView}
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="appPw"
                    label="비밀번호"
                    placeholder="비밀번호를 입력해주세요"
                    type="password"
                    value={!isDetailView ? appEquipment.appPw || "" : "******"}
                    onChange={handleChange}
                    initialErrorMessage="비밀번호를 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired={!isDetailView}
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="appName"
                    label="앱(장비)명"
                    placeholder="앱(장비)명을 입력해주세요"
                    type="text"
                    value={appEquipment.appName || ""}
                    onChange={handleChange}
                    initialErrorMessage="앱(장비)명을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                {isDetailView && (
                  <Row>
                    <FormInputTypes
                      id="registrationId"
                      label="FCM 등록 ID"
                      type="text"
                      value={appEquipment.registrationId || ""}
                      onChange={handleChange}
                      isDisabled
                    />
                  </Row>
                )}
                <Row className="mb-3">
                  <Selector
                    label="고객사"
                    id="clientIdx"
                    options={clients.map((client) => ({
                      label: client.clientName,
                      value: client.clientIdx,
                    }))}
                    value={appEquipment.clientIdx}
                    onChange={handleChange}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <Selector
                    label="회원상태"
                    id="stateFlag"
                    options={pureStateOptions.map((exportType) => ({
                      label: exportType.label,
                      value: exportType.value,
                    }))}
                    value={appEquipment.stateFlag}
                    onChange={handleChange}
                    isRequired
                  />
                </Row>
                <FormButtonsTypes
                  isDetailView={isDetailView}
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton={true}
                />
                <ConfirmModalTypes
                  isLoading={loading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveAppEquipment}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default AppEquipmentForm;
