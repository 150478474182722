import { Col, Input, Label } from "reactstrap";

import bridgeAnterior from "../../assets/images/printingProfile/bridge-anterior.png";
import bridgeFull from "../../assets/images/printingProfile/bridge-full.png";
import bridgeOthers from "../../assets/images/printingProfile/bridge-others.png";
import bridgePosterior from "../../assets/images/printingProfile/bridge-posterior.png";
import crownCanine from "../../assets/images/printingProfile/crown-canine.png";
import crownIncisor from "../../assets/images/printingProfile/crown-incisor.png";
import crownMolar from "../../assets/images/printingProfile/crown-molar.png";
import crownPremolar from "../../assets/images/printingProfile/crown-premolar.png";
import dentalModelOneThird from "../../assets/images/printingProfile/dentalModel-1-3.png";
import dentalModelTwoThird from "../../assets/images/printingProfile/dentalModel-2-3.png";
import dentalModelFilled from "../../assets/images/printingProfile/dentalModel-filled.png";
import dentalModelFull from "../../assets/images/printingProfile/dentalModel-full.png";
import dentalModelHollow from "../../assets/images/printingProfile/dentalModel-hollow.png";
import dentalModelHoneycomb from "../../assets/images/printingProfile/dentalModel-honeycomb.png";
import dentalModelNo from "../../assets/images/printingProfile/dentalModel-no.png";
import dentalModelYes from "../../assets/images/printingProfile/dentalModel-yes.png";
import surgicalGuideOneThird from "../../assets/images/printingProfile/surgicalGuide-1-3.png";
import surgicalGuideTwoThird from "../../assets/images/printingProfile/surgicalGuide-2-3.png";
import surgicalGuideFull from "../../assets/images/printingProfile/surgicalGuide-full.png";
import { ListHoverImage, RadioContainer } from "../styled";

const crownOptions = [
  {
    label: "Incisor",
    value: 0,
    imgSrc: crownIncisor,
  },
  {
    label: "Canine",
    value: 1,
    imgSrc: crownCanine,
  },
  {
    label: "Premolar",
    value: 2,
    imgSrc: crownPremolar,
  },
  {
    label: "Molar",
    value: 3,
    imgSrc: crownMolar,
  },
];

const bridgeOptions = [
  {
    label: "Anterior",
    value: 0,
    imgSrc: bridgeAnterior,
  },
  {
    label: "Posterior",
    value: 1,
    imgSrc: bridgePosterior,
  },
  {
    label: "Full",
    value: 2,
    imgSrc: bridgeFull,
  },
  {
    label: "Others",
    value: 3,
    imgSrc: bridgeOthers,
  },
];

const surgicalGuideSizeOptions = [
  {
    label: "Full Arch",
    value: 0,
    imgSrc: surgicalGuideFull,
  },
  {
    label: "2/3",
    value: 1,
    imgSrc: surgicalGuideTwoThird,
  },
  {
    label: "1/3",
    value: 2,
    imgSrc: surgicalGuideOneThird,
  },
];

const dentalSizeOptions = [
  {
    label: "Full Arch",
    value: 0,
    imgSrc: dentalModelFull,
  },
  {
    label: "2/3",
    value: 1,
    imgSrc: dentalModelTwoThird,
  },
  {
    label: "1/3",
    value: 2,
    imgSrc: dentalModelOneThird,
  },
];

const dentalPalatalOptions = [
  {
    label: "No",
    value: 0,
    imgSrc: dentalModelNo,
  },
  {
    label: "Yes",
    value: 1,
    imgSrc: dentalModelYes,
  },
];

const dentalBottomOptions = [
  {
    label: "Filled",
    value: 0,
    imgSrc: dentalModelFilled,
  },
  {
    label: "Honeycomb",
    value: 1,
    imgSrc: dentalModelHoneycomb,
  },
  {
    label: "Hollow",
    value: 2,
    imgSrc: dentalModelHollow,
  },
];

const RenderOptions = ({ profile, selectedApplication, handleChange }) => {
  switch (selectedApplication.applicationsIdx) {
    case 1:
      return (
        // <Col xl="3" className="mb-4 ">
        <Col xl="2" md="3" xs="6" className="mb-4">
          <Label className="form-label h6">Types</Label>
          {crownOptions.map((option) => (
            <RadioContainer className="form-check mb-2" key={option.value}>
              <Input
                type="radio"
                id={`crown-${option.value}`}
                name="type"
                className="form-check-input"
                value={option.value.toString()}
                checked={profile.type?.toString() === option.value.toString()}
                onChange={handleChange}
              />
              <Label
                htmlFor={`crown-${option.value}`}
                className="form-check-label"
              >
                {option.label}
              </Label>
              <span>
                <i className="ri-information-line"></i>
              </span>

              <ListHoverImage
                style={{
                  backgroundImage: `url(${option.imgSrc})`,
                }}
              />
            </RadioContainer>
          ))}
        </Col>
      );
    case 2:
      return (
        // <Col xl="2" className="mb-4">
        <Col xl="2" md="3" xs="6" className="mb-4">
          <Label className="form-label h6">Part</Label>
          {bridgeOptions.map((option) => (
            <RadioContainer className="form-check mb-2" key={option.value}>
              <Input
                type="radio"
                id={`bridge-${option.value}`}
                name="part"
                className="form-check-input"
                value={option.value.toString()}
                checked={profile.part?.toString() === option.value.toString()}
                onChange={handleChange}
              />
              <Label
                htmlFor={`bridge-${option.value}`}
                className="form-check-label"
              >
                {option.label}
              </Label>
              <span>
                <i className="ri-information-line"></i>
              </span>
              <ListHoverImage
                style={{
                  backgroundImage: `url(${option.imgSrc})`,
                }}
              />
            </RadioContainer>
          ))}
        </Col>
      );
    case 3:
      return (
        // <Col xl="2" className="mb-4">
        <Col xl="2" md="3" xs="6" className="mb-4">
          <Label className="form-label h6">Size</Label>
          {surgicalGuideSizeOptions.map((option) => (
            <RadioContainer className="form-check mb-2" key={option.value}>
              <Input
                type="radio"
                id={`size-${option.value}`}
                name="size"
                className="form-check-input"
                value={option.value.toString()}
                checked={profile.size?.toString() === option.value.toString()}
                onChange={handleChange}
              />
              <Label
                htmlFor={`size-${option.value}`}
                className="form-check-label"
              >
                {option.label}
              </Label>
              <span>
                <i className="ri-information-line"></i>
              </span>
              <ListHoverImage
                style={{
                  backgroundImage: `url(${option.imgSrc})`,
                }}
              />
            </RadioContainer>
          ))}
        </Col>
      );
    case 4:
      return (
        // <Row style={{ display: "flex", gap: "70px" }}>
        <>
          <Col xl="2" md="3" xs="6" className="mb-4">
            {/* <Col xl="2" className="mb-4"> */}
            <Label className="form-label h6">Size</Label>
            {dentalSizeOptions.map((option) => (
              <RadioContainer className="form-check mb-2" key={option.value}>
                <Input
                  type="radio"
                  id={`size-${option.value}`}
                  name="size"
                  className="form-check-input"
                  value={option.value.toString()}
                  checked={profile.size?.toString() === option.value.toString()}
                  onChange={handleChange}
                />
                <Label
                  htmlFor={`size-${option.value}`}
                  className="form-check-label"
                >
                  {option.label}
                </Label>
                <span>
                  <i className="ri-information-line"></i>
                </span>
                <ListHoverImage
                  style={{
                    backgroundImage: `url(${option.imgSrc})`,
                  }}
                />
              </RadioContainer>
            ))}
          </Col>
          <Col xl="2" md="3" xs="6" className="mb-4">
            {/* <Col xl="2" className="mb-4"> */}
            <Label className="form-label h6">Palatal</Label>
            {dentalPalatalOptions.map((option) => (
              <RadioContainer className="form-check mb-2" key={option.value}>
                <Input
                  type="radio"
                  id={`palatal-${option.value}`}
                  name="palatal"
                  className="form-check-input"
                  value={option.value.toString()}
                  checked={
                    profile.palatal?.toString() === option.value.toString()
                  }
                  onChange={handleChange}
                />
                <Label
                  htmlFor={`palatal-${option.value}`}
                  className="form-check-label"
                >
                  {option.label}
                </Label>
                <span>
                  <i className="ri-information-line"></i>
                </span>
                <ListHoverImage
                  style={{
                    backgroundImage: `url(${option.imgSrc})`,
                  }}
                />
              </RadioContainer>
            ))}
          </Col>
          <Col xl="2" md="3" xs="6" className="mb-4">
            {/* <Col xl="2" className="mb-4"> */}
            <Label className="form-label h6">Bottom</Label>
            {dentalBottomOptions.map((option) => (
              <RadioContainer className="form-check mb-2" key={option.value}>
                <Input
                  type="radio"
                  id={`bottom-${option.value}`}
                  name="bottom"
                  className="form-check-input"
                  value={option.value.toString()}
                  checked={
                    profile.bottom?.toString() === option.value.toString()
                  }
                  onChange={handleChange}
                />
                <Label
                  htmlFor={`bottom-${option.value}`}
                  className="form-check-label"
                >
                  {option.label}
                </Label>
                <span>
                  <i className="ri-information-line"></i>
                </span>
                <ListHoverImage
                  style={{
                    backgroundImage: `url(${option.imgSrc})`,
                  }}
                />
              </RadioContainer>
            ))}
          </Col>
          {/* </Row> */}
        </>
      );
    default:
      return null;
  }
};

export default RenderOptions;
