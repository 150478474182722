import { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import CustomCkEditor from "@/pages/EmailTemplate/components/CustomCkEditor";
import { QUESTION_TYPE_OPTIONS } from ".";

type QnaProps = Partial<
  Pick<
    Api.Response.Qna,
    "qnaIdx" | "name" | "type" | "email" | "questions" | "answers"
  >
>;

const DEFAULT_QNA: QnaProps = {
  name: "",
  type: QUESTION_TYPE_OPTIONS[0].value,
  email: "",
  questions: "",
  answers: "",
};

const BREADCRUMB_ITEMS = [
  { title: "Qna Management", link: "#" },
  { title: "Update", link: "#" },
];

const QnaDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();
  const { qnaIdx } = useParams();

  const [qna, setQna] = useState(DEFAULT_QNA);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  useEffect(() => {
    const getQnaByIdx = async () => {
      if (!qnaIdx) return;
      try {
        const response = await Api.Qna.get(parseInt(qnaIdx, 10));
        const existingQna = response.data;
        if (existingQna) {
          setQna(existingQna);
        }
      } catch (error) {
        console.error("Error fetching Qna from API", error);
      }
    };
    getQnaByIdx();
  }, [qnaIdx]);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
    };
  }) => {
    const { name, value } = e.target;
    setQna((prevVer) => ({ ...prevVer, [name]: value }));
  };

  const handleSaveFaq = async () => {
    if (!qna.answers) {
      toast.error("답변내용을 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }
    setIsLoading(true);

    try {
      if (typeof qna?.qnaIdx !== "number") return;
      const requestData = {
        qnaIdx: qna.qnaIdx,
        answers: qna.answers,
      };

      const response = await Api.Qna.update(requestData);
      const successMessage = getLocalizedMessage(response, "success");
      setIsOpen(false);
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate(`/qna${location.search}`);
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error: updating Q&A", error);
      } else {
        console.error("Unexpected error", error);
      }
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/qna${location.search}`);
  };

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Qna Management">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">게시물정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="name"
                    label="이름"
                    placeholder=""
                    type="text"
                    value={qna.name || ""}
                    onChange={handleChange}
                    isDisabled
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="email"
                    label="이메일"
                    placeholder=""
                    type="text"
                    value={qna.email || ""}
                    onChange={handleChange}
                    isDisabled
                  />
                </Row>
                <Row>
                  <CustomLabel htmlFor="questions" title="질문내용" />
                  <Col>
                    <CustomCkEditor
                      id="questions"
                      data={qna.questions || ""}
                      isDisabled
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel htmlFor="answers" title="답변내용" isRequired />
                  <Col>
                    <CustomCkEditor
                      id="answers"
                      data={qna.answers || ""}
                      isFocused={isEditorFocused}
                      handleFocus={() => setIsEditorFocused(true)}
                      handleBlur={() => setIsEditorFocused(false)}
                      onChange={(answers: string) =>
                        setQna((prev) => ({ ...prev, answers }))
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <FormButtonsTypes
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                </Row>
                <ConfirmModalTypes
                  isLoading={isLoading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveFaq}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default QnaDetails;
