import { create } from "zustand";
import * as Api from "@/api";

type CustomerStoreState = {
  customers: Api.Response.ClientItem[];
  loading: boolean;
  error: string | null;
  setCustomers: (customers: Api.Response.ClientItem[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useCustomerStore = create<CustomerStoreState>((set) => ({
  customers: [],
  loading: false,
  error: null,
  setCustomers: (customers) => set({ customers }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useCustomerStore;
