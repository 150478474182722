import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import ControlPanelTypes from "@/components/Common/ControlPanelTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import {
  ClickableCell,
  DisplayCellType,
} from "@/pages/Utility/CustomCellsType";
import { STATUS_OPTIONS } from "@/pages/Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Qna Management", link: "#" },
  { title: "List", link: "#" },
];

export const QUESTION_TYPE_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "제품", value: 0 },
  { label: "마케팅", value: 1 },
  { label: "파트너쉽", value: 2 },
  { label: "채용", value: 3 },
];

const Qna = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();

  const [qnas, setQnas] = useState<Api.Response.QnaItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalQnas, setTotalQnas] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [questionType, setQuestionType] = useState<number>(
    parseQueryParam(queryParams.question) ?? QUESTION_TYPE_OPTIONS[0].value,
  );
  const [selectedStatus, setSelectedStatus] = useState<number>(() => {
    const parsedStatus = parseQueryParam(queryParams.show);
    return parsedStatus !== null ? parsedStatus : STATUS_OPTIONS[0].value;
  });
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { qnaIdx: number }) => {
      navigate(`/qna/${item.qnaIdx}${window.location.search}`);
    },
    [navigate],
  );

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      question: questionType,
      show: selectedStatus,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    questionType,
    selectedStatus,
    location.pathname,
    navigate,
  ]);

  const fetchQnas = useCallback(async () => {
    setIsLoading(true);
    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        type: questionType,
        isAnswers: selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Qna.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalQnas(response.paging.totalRecord);
      setQnas(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching Q&As list", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    questionType,
    selectedStatus,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchQnas();
  }, [fetchQnas]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchQnas();
  };

  const handleQuestionTypeSearch = (newQuestionType: number) => {
    setQuestionType(newQuestionType);
    setCurrentPage(0);
  };

  const handleStatusSearch = (newStatus: number | string) => {
    setCurrentPage(0);
    setSelectedStatus(newStatus as number);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setQuestionType(-1);
    setSelectedStatus(STATUS_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.QnaItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "qnaIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "무의유형",
        accessor: "type",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.QnaItem>) => (
          <span>
            {
              QUESTION_TYPE_OPTIONS.find((option) => option.value === value)
                ?.label
            }
          </span>
        ),
      },
      {
        Header: "이름",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이메일",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }: CellProps<Api.Response.QnaItem>) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "문의내용",
        accessor: "questions",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "처리여부",
        accessor: "isAnswers",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.QnaItem>) => (
          <DisplayCellType value={value} options={STATUS_OPTIONS} />
        ),
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer title="Qna Management" breadcrumbItems={BREADCRUMB_ITEMS}>
        <ControlPanelTypes
          placeholder="이름, 이메일, 질문내용으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={QUESTION_TYPE_OPTIONS}
          option1Type="number"
          options2={STATUS_OPTIONS}
          option2Type="number"
          selectedOption1={questionType}
          setSelectedOption1={handleQuestionTypeSearch}
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle1="문의유형"
          selectTitle2="처리 여부"
        />
        <CustomTableContainerTypes
          btnTitle=""
          sortByIdx="qnaIdx"
          isLoading={isLoading}
          columns={columns || []}
          data={qnas || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalQnas}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Qna;
