import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { format } from "date-fns";
import Flatpickr from "react-flatpickr";
import { CellProps } from "react-table";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { InputGroup } from "reactstrap";
import * as Api from "@/api";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import { CustomTooltip } from "@/components/Common/styled";
import useQueryParams from "@/helpers/hooks";
import { CustomInputGroupText } from "../styled";

type UsageActivityModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedUser?: number | null;
};

const UsageActivityModal = ({
  isOpen,
  setIsOpen,
  selectedUser,
}: UsageActivityModalProps) => {
  const { pageSize, page, dateRange } = useQueryParams();
  const [usageActivity, setUsageActivity] = useState<
    Api.Response.UserLogItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalUsageActivity, setTotalUsageActivity] = useState(0);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [isHovered, setIsHovered] = useState(false);
  const flatpickrRef = useRef<null | Flatpickr>(null);

  const handleIconClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    clearFilters();
  };

  const fetchUserLogs = useCallback(async () => {
    if (!selectedUser) return;
    setIsLoading(true);
    try {
      const requestData: Api.Request.GetUserLogList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        userIdx: selectedUser,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };
      const response = await Api.User.getLogList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalUsageActivity(response.paging.totalRecord);
      setUsageActivity(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching User Log List", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customPageSize, selectedDates, selectedUser]);

  useEffect(() => {
    fetchUserLogs();
  }, [fetchUserLogs]);

  const handleDateChange = (selectedDates: Date[]) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }) as [string, string];
      setSelectedDates(formattedDates);
    }
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSelectedDates(["", ""]);
    setCustomPageSize(10);
    setCurrentPage(0);
  };

  const columns: CustomColumn<Api.Response.UserLogItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "logIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "구분",
        accessor: "logType",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.UserLogItem>) => (
          <span style={{ color: Number(value) === 1 ? "#ff3d5f" : "#5664d2" }}>
            {Number(value) === 1 ? "요청" : "응답"}
          </span>
        ),
      },
      {
        Header: "주소",
        accessor: "url",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "파라메타",
        accessor: "param",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "IP",
        accessor: "ip",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>사용기록</ModalHeader>
      <ModalBody>
        <Row>
          <CustomTableContainerTypes
            sortByIdx="logIdx"
            columns={columns || []}
            data={usageActivity || []}
            customPageSize={customPageSize}
            totalPage={totalPage}
            totalRecord={totalUsageActivity}
            setCustomPageSize={setCustomPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
            isFullText
          >
            <Col sm={8} className="form-control-sm w-100 p-0">
              <InputGroup className="cursor-pointer">
                <Flatpickr
                  ref={flatpickrRef}
                  className="form-control d-block form-control-sm"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                  }}
                  value={dateRange}
                  onChange={handleDateChange}
                />
                <CustomInputGroupText
                  className="custom-color"
                  onClick={handleIconClick}
                  id="CalendarTooltip"
                >
                  <i className="ri-calendar-event-fill cursor-pointer"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered}
                  target="CalendarTooltip"
                  toggle={(isHovered: boolean) => setIsHovered(!isHovered)}
                >
                  기간 선택
                </CustomTooltip>
              </InputGroup>
            </Col>
          </CustomTableContainerTypes>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UsageActivityModal;
