import { Tooltip } from "reactstrap";
import styled from "styled-components";

export const DashboardTooltip = styled(Tooltip)`
  .tooltip-inner {
    padding-top: 10px !important;
    white-space: pre-line;
  }
`;

export const ChartContainer = styled.div`
  width: 100%; /* Default to full width */

  &:first-child {
    width: 66.67%; /* Equivalent to col-lg-8 */
  }

  &:last-child {
    width: 33.33%; /* Equivalent to col-lg-4 */
  }

  &:first-child:last-child {
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    /* Example for medium devices */
    &:first-child {
      width: 100%; /* Stack on smaller screens */
    }

    &:last-child {
      width: 100%; /* Stack on smaller screens */
    }
  }
`;
