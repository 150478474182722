import { create } from "zustand";

// Utility functions to manipulate DOM elements
const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
};

const manageBodyClass = (cssClass, action = "toggle") => {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }
};

const useLayoutStore = create((set) => ({
  layoutType: "vertical",
  layoutWidth: "fluid",

  leftSideBarTheme: "light",
  leftSideBarType: "default",

  sidebarTheme: "dark",
  sidebarType: "default",

  topbarTheme: "light",
  theme: "light",

  isPreloader: false,

  showRightSidebar: false,
  showLeftSidebar: false,
  rightSidebarVisible: false,

  // isMobile: false,
  isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),

  // changeLayout: (layout) => {
  //   set((state) => {
  //     changeBodyAttribute("data-layout", layout);
  //     if (layout === "horizontal") {
  //       changeBodyAttribute("data-topbar", "dark");
  //       document.body.removeAttribute("data-sidebar");
  //       document.body.removeAttribute("data-sidebar-size");
  //     } else {
  //       changeBodyAttribute("data-topbar", "light");
  //     }
  //     return { layoutType: layout };
  //   });
  // },

  changeLayout: (layout) => {
    changeBodyAttribute("data-layout", layout);
    if (layout === "horizontal") {
      changeBodyAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      changeBodyAttribute("data-topbar", "light");
    }
    set({ layoutType: layout });
  },

  changeLayoutWidth: (width) => {
    set((state) => {
      changeBodyAttribute("data-layout-size", width);
      if (state.layoutType === "vertical") {
        changeBodyAttribute("data-sidebar", state.sidebarTheme);
        if (width === "boxed") {
          manageBodyClass("vertical-collpsed", "add");
        } else {
          manageBodyClass("vertical-collpsed", "remove");
        }
      }
      return { layoutWidth: width };
    });
  },

  changeSidebarTheme: (theme) => {
    set(() => {
      changeBodyAttribute("data-sidebar", theme);
      return { sidebarTheme: theme };
    });
  },

  // changeSidebarType: (type, isMobile) => {
  //   set((state) => {
  //     switch (type) {
  //       case "compact":
  //         changeBodyAttribute("data-sidebar-size", "small");
  //         manageBodyClass("sidebar-enable", "remove");
  //         manageBodyClass("vertical-collpsed", "remove");
  //         break;
  //       case "icon":
  //         changeBodyAttribute("data-keep-enlarged", "true");
  //         manageBodyClass("vertical-collpsed", "add");
  //         break;
  //       case "condensed":
  //         manageBodyClass("sidebar-enable", "add");
  //         if (!isMobile) manageBodyClass("vertical-collpsed", "add");
  //         break;
  //       default:
  //         changeBodyAttribute("data-sidebar-size", "");
  //         manageBodyClass("sidebar-enable", "remove");
  //         if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
  //         break;
  //     }
  //     return { sidebarType: type };
  //   }, false);
  // },

  changeSidebarType: (type, isMobile) => {
    switch (type) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (!isMobile) manageBodyClass("vertical-collpsed", "add");
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
        break;
    }
    set({ sidebarType: type, leftSideBarType: type });
  },

  changeTopbarTheme: (theme) => {
    set(() => {
      changeBodyAttribute("data-topbar", theme);
      return { topbarTheme: theme };
    });
  },

  changeLayoutTheme: (theme) => {
    set(() => {
      changeBodyAttribute("data-bs-theme", theme);
      return { theme: theme };
    });
  },

  toggleRightSidebar: () => {
    set((state) => {
      manageBodyClass("right-bar-enabled");
      return { rightSidebarVisible: !state.rightSidebarVisible };
    });
  },

  // eslint-disable-next-line no-dupe-keys
  showRightSidebar: () => {
    set(() => {
      manageBodyClass("right-bar-enabled", "add");
      return { rightSidebarVisible: true };
    });
  },

  hideRightSidebar: () => {
    set(() => {
      manageBodyClass("right-bar-enabled", "remove");
      return { rightSidebarVisible: false };
    });
  },

  changePreloader: (isPreloader) => set({ isPreloader }),

  setPreloader: (value) => {
    set(() => {
      if (value) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
        setTimeout(() => {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      } else {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }
      return { isPreloader: value };
    });
  },
}));

export default useLayoutStore;
