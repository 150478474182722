import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import CustomCkEditor from "@/pages/EmailTemplate/components/CustomCkEditor";

type FaqFormProps = {
  isDetailView: boolean;
};

type FaqProps = Api.Request.AddFaq & Partial<Pick<Api.Response.Faq, "faqIdx">>;

const DEFAULT_FAQ: FaqProps = {
  titleKo: "",
  titleEn: "",
  titleRu: "",
  contentKo: "",
  contentEn: "",
  contentRu: "",
  displayFlag: 1,
};

const FaqForm = ({ isDetailView }: FaqFormProps) => {
  const breadcrumbItems = [
    { title: "Faq Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();
  const { faqIdx } = useParams();

  const [faq, setFaq] = useState(DEFAULT_FAQ);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [focusedEditors, setFocusedEditors] = useState({
    contentKo: false,
    contentEn: false,
    contentRu: false,
  });

  const getFaqByIdx = useCallback(async () => {
    if (!faqIdx) return;
    try {
      const response = await Api.Faq.get(parseInt(faqIdx, 10));
      const existingFaq = response.data;
      if (existingFaq) {
        const updatedFaq = {
          faqIdx: existingFaq.faqIdx,
          titleKo: existingFaq.titles.ko,
          titleEn: existingFaq.titles.en,
          titleRu: existingFaq.titles.ru,
          contentKo: existingFaq.contents.ko,
          contentEn: existingFaq.contents.en,
          contentRu: existingFaq.contents.ru,
          displayFlag: existingFaq.displayFlag,
        };
        setFaq(updatedFaq);
      }
    } catch (error) {
      console.error("Error fetching Faq from API", error);
    }
  }, [faqIdx]);

  useEffect(() => {
    if (isDetailView) {
      getFaqByIdx();
    }
  }, [getFaqByIdx, isDetailView]);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
      checked?: boolean;
    };
  }) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFaq((prevVer) => ({ ...prevVer, [name]: newValue }));
  };

  const handleSaveFaq = async () => {
    if (
      !faq.titleKo ||
      !faq.titleEn ||
      !faq.titleRu ||
      !faq.contentKo ||
      !faq.contentEn ||
      !faq.contentRu
    ) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }
    setIsLoading(true);

    try {
      let response;
      const requestData = {
        titleKo: faq.titleKo,
        titleEn: faq.titleEn,
        titleRu: faq.titleRu,
        contentKo: faq.contentKo,
        contentEn: faq.contentEn,
        contentRu: faq.contentRu,
        displayFlag: faq.displayFlag,
      };

      if (isDetailView) {
        if (typeof faq?.faqIdx !== "number") return;
        response = await Api.Faq.update({
          ...requestData,
          faqIdx: faq.faqIdx,
        });
      } else {
        response = await Api.Faq.add(requestData);
      }

      const successMessage = getLocalizedMessage(response, "success");
      setIsOpen(false);
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/faq${location.search}`);
        } else {
          navigate("/faq");
        }
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error: adding new faq", error);
      } else {
        console.error("Unexpected error", error);
      }
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/faq${location.search}`);
  };

  const handleDeleteFaq = async () => {
    if (!faq.faqIdx) return;
    setIsLoading(true);
    try {
      const response = await Api.Faq.delete(faq?.faqIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/faq");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setFocusAs = (editorName: string, focusState: boolean) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: focusState }));
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Faq Management">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">FAQ정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row>
                  <FormInputTypes
                    id="titleKo"
                    label="제목 한글"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={faq.titleKo || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="titleEn"
                    label="제목 영문"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={faq.titleEn || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="titleRu"
                    label="제목 러시아어"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={faq.titleRu || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>

                <Row>
                  <CustomLabel
                    htmlFor="contentKo"
                    title="내용 한글"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentKo"
                      data={faq.contentKo || ""}
                      isFocused={focusedEditors.contentKo}
                      handleFocus={() => setFocusAs("contentKo", true)}
                      handleBlur={() => setFocusAs("contentKo", false)}
                      onChange={(contentKo: string) =>
                        setFaq((prev) => ({ ...prev, contentKo }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel
                    htmlFor="contentEn"
                    title="내용 영문"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentEn"
                      data={faq.contentEn || ""}
                      isFocused={focusedEditors.contentEn}
                      handleFocus={() => setFocusAs("contentEn", true)}
                      handleBlur={() => setFocusAs("contentEn", false)}
                      onChange={(contentEn: string) =>
                        setFaq((prev) => ({ ...prev, contentEn }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel
                    htmlFor="contentRu"
                    title="내용 러시아어"
                    isRequired
                  />
                  <Col>
                    <CustomCkEditor
                      id="contentRu"
                      data={faq.contentRu || ""}
                      isFocused={focusedEditors.contentRu}
                      handleFocus={() => setFocusAs("contentRu", true)}
                      handleBlur={() => setFocusAs("contentRu", false)}
                      onChange={(contentRu: string) =>
                        setFaq((prev) => ({ ...prev, contentRu }))
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={faq.displayFlag === 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setFaq({ ...faq, displayFlag: 1 });
                      } else {
                        setFaq({ ...faq, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                </Row>
                <ConfirmModalTypes
                  isLoading={isLoading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveFaq}
                  handleDelete={handleDeleteFaq}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default FaqForm;
