import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import * as Api from "@/api";
import ActiveUserRate from "./ActiveUserRate";
import ApiRequest from "./ApiRequest";
import CardRow from "./CardRow";
import ConnectionChart from "./ConnectionChart";
import JoinChart from "./JoinChart";
import NewSubscriber from "./NewSubscriber";
import RequestByCountry from "./RequestByCountry";
import RequestByRegion from "./RequestByRegion";
import TopConnected from "./TopConnected";
import TopRequestModel from "./TopRequestModel";
import TopRequestRegion from "./TopRequestRegion";
import UsageByFunction from "./UsageByFunction";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const BREADCRUMB_ITEMS = [
  { title: "Metamorp", link: "/" },
  { title: "Dashboard", link: "#" },
];

const Dashboard = () => {
  const [userRegData, setUserRegData] =
    useState<Api.Response.UserRegistration | null>(null);

  const [userConnectData, setUserConnectData] =
    useState<Api.Response.UserConnection | null>(null);

  const [aiRequestData, setAiRequestData] =
    useState<Api.Response.UserAiRequest | null>(null);

  const [apiRequestData, setApiRequestData] =
    useState<Api.Response.UserApiRequest | null>(null);

  const [connectionState, setConnectionState] =
    useState<Api.Response.ConnectionState>({
      dateList: [],
      pvList: [],
      uvList: [],
    });

  const [registrationState, setRegistrationState] =
    useState<Api.Response.RegistrationState>({
      dateList: [],
      cntList: [],
    });

  const [apiRequestState, setApiRequestState] =
    useState<Api.Response.ApiRequestState>({
      dateList: [],
      apiList: [],
      aiList: [],
    });

  const [topRequestModel, setTopRequestModel] =
    useState<Api.Response.TopRequestModel>({
      classCodeList: [],
      cntList: [],
    });

  const [topRequestRegion, setTopRequestRegion] =
    useState<Api.Response.TopRequestRegion>({
      countryList: [],
      reqCntList: [],
      userCntList: [],
    });

  const [topConnected, setTopConnected] = useState<
    Api.Response.TopConnectedUser[]
  >([]);

  const [activeRate, setActiveRate] = useState<Api.Response.ActiveRate>({
    dateList: [],
    connectedCntList: [],
    activeRateList: [],
  });

  const [newSubscribers, setNewSubscribers] = useState<
    Api.Response.NewSubscriber[]
  >([]);

  const [requestCountry, setRequestCountry] = useState<
    Api.Response.RequestCountry[]
  >([]);

  const [requestRegion, setRequestRegion] = useState<
    Api.Response.RequestRegion[]
  >([]);

  const [usageByFunction, setUsageByFunction] =
    useState<Api.Response.UsedFunction>({
      dateList: [],
      cnt0List: [],
      cnt1List: [],
      cnt2List: [],
      cnt3List: [],
      cnt4List: [],
      cnt5List: [],
      cnt6List: [],
      cnt7List: [],
      cnt8List: [],
      cnt9List: [],
      cnt10List: [],
      cnt11List: [],
      cnt12List: [],
    });
  const [usageByFunctionLoading, setUsageByFunctionLoading] = useState(false);

  const [connectionDays, setConnectionDays] = useState(30);
  const [registrationDays, setRegistrationDays] = useState(30);
  const [apiReqDays, setApiReqDays] = useState(30);
  const [reqModelDays, setReqModelDays] = useState(90);
  const [reqRegionDays, setReqRegionDays] = useState(90);
  const [activeDays, setActiveDays] = useState(30);
  const [reqCountryDays, setReqCountryDays] = useState(90);
  const [reqKoreaRegionDays, setKoreaReqRegionDays] = useState(90);
  const [usageByFuncDays, setUsageByFuncDays] = useState(14);

  const fetchUserRegistration = async () => {
    try {
      const response = await Api.Dashboard.getUserRegistration();
      setUserRegData(response.data);
    } catch (error) {
      console.error("Failed fetching User Registration data", error);
    }
  };

  const fetchUserConnection = async () => {
    try {
      const response = await Api.Dashboard.getUserConnection();
      setUserConnectData(response.data);
    } catch (error) {
      console.error("Failed fetching User Connection data", error);
    }
  };

  const fetchAiRequest = async () => {
    try {
      const response = await Api.Dashboard.getAiData();
      setAiRequestData(response.data);
    } catch (error) {
      console.error("Failed fetching Ai Request data", error);
    }
  };

  const fetchApiRequest = async () => {
    try {
      const response = await Api.Dashboard.getApiData();
      setApiRequestData(response.data);
    } catch (error) {
      console.error("Failed fetching Api Request data", error);
    }
  };

  const fetchConnectionChart = async (days: number) => {
    try {
      const response = await Api.Dashboard.getConnectionState(days);
      setConnectionState(response.data);
    } catch (error) {
      console.error("Failed fetching Connection State data", error);
    }
  };

  const fetchRegistrationChart = async (days: number) => {
    try {
      const response = await Api.Dashboard.getRegistrationState(days);
      setRegistrationState(response.data);
    } catch (error) {
      console.error("Failed fetching Registration State data", error);
    }
  };

  const fetchApiRequestChart = async (days: number) => {
    try {
      const response = await Api.Dashboard.getApiRequestState(days);
      setApiRequestState(response.data);
    } catch (error) {
      console.error("Failed fetching API Request State data", error);
    }
  };

  const fetchTopRequestModel = async (days: number) => {
    try {
      const response = await Api.Dashboard.getTopRequestModel(days);
      setTopRequestModel(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Model data", error);
    }
  };

  const fetchTopRequestRegion = async (days: number) => {
    try {
      const response = await Api.Dashboard.getTopRequestRegion(days);
      setTopRequestRegion(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Region data", error);
    }
  };

  const fetchTopConnected = async () => {
    try {
      const response = await Api.Dashboard.getTopConnectedUser();
      setTopConnected(response.data);
    } catch (error) {
      console.error("Failed fetching Top Connected Users data", error);
    }
  };

  const fetchActiveUserRate = async (days: number) => {
    try {
      const response = await Api.Dashboard.getActiveUserRate(days);
      setActiveRate(response.data);
    } catch (error) {
      console.error("Failed fetching Top Request Region data", error);
    }
  };

  const fetchNewSubscribers = async () => {
    try {
      const response = await Api.Dashboard.getNewSubscribers();
      setNewSubscribers(response.data);
    } catch (error) {
      console.error("Failed fetching Recently Joined Users data", error);
    }
  };

  const fetchRequestCountries = async (days: number) => {
    try {
      const response = await Api.Dashboard.getRequestByCountry(days);
      setRequestCountry(response.data);
    } catch (error) {
      console.error("Failed fetching Request By Country data", error);
    }
  };

  const fetchRequestRegions = async (days: number) => {
    try {
      const response = await Api.Dashboard.getRequestByRegion(days);
      setRequestRegion(response.data);
    } catch (error) {
      console.error("Failed fetching Request By Country data", error);
    }
  };

  const fetchUsageByFunction = async (days: number) => {
    setUsageByFunctionLoading(true);
    try {
      const response = await Api.Dashboard.getUseByFunction(days);
      setUsageByFunction(response.data);
    } catch (error) {
      console.error("Failed fetching Frequency of use by Function data", error);
    } finally {
      setUsageByFunctionLoading(false);
    }
  };

  useEffect(() => {
    fetchUserRegistration();
    fetchUserConnection();
    fetchAiRequest();
    fetchApiRequest();
    fetchTopConnected();
    fetchNewSubscribers();
  }, []);

  useEffect(() => {
    fetchConnectionChart(connectionDays);
    fetchRegistrationChart(registrationDays);
    fetchApiRequestChart(apiReqDays);
    fetchActiveUserRate(activeDays);
    fetchTopRequestModel(reqModelDays);
    fetchTopRequestRegion(reqRegionDays);
    fetchRequestCountries(reqCountryDays);
    fetchRequestRegions(reqKoreaRegionDays);
    fetchUsageByFunction(usageByFuncDays);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={BREADCRUMB_ITEMS} />
          {userRegData &&
            userConnectData &&
            aiRequestData &&
            apiRequestData && (
              <CardRow
                registration={userRegData}
                connection={userConnectData}
                ai={aiRequestData}
                api={apiRequestData}
              />
            )}
          <Row>
            {connectionState && (
              <ConnectionChart
                data={connectionState}
                selectedDays={connectionDays}
                onDaysSelection={(days) => {
                  setConnectionDays(days);
                  fetchConnectionChart(days);
                }}
                isHeader
              />
            )}
            {registrationState && (
              <JoinChart
                data={registrationState}
                selectedDays={registrationDays}
                onDaysSelection={(days) => {
                  setRegistrationDays(days);
                  fetchRegistrationChart(days);
                }}
                isHeader
              />
            )}
          </Row>
          <Row>
            {apiRequestState && (
              <ApiRequest
                data={apiRequestState}
                selectedDays={apiReqDays}
                onDaysSelection={(days) => {
                  setApiReqDays(days);
                  fetchApiRequestChart(days);
                }}
                isHeader
              />
            )}
            {topRequestModel && (
              <TopRequestModel
                data={topRequestModel}
                selectedDays={reqModelDays}
                onDaysSelection={(days) => {
                  setReqModelDays(days);
                  fetchTopRequestModel(days);
                }}
                isHeader
              />
            )}
          </Row>
          <Row>
            {topRequestRegion && (
              <TopRequestRegion
                data={topRequestRegion}
                selectedDays={reqRegionDays}
                onDaysSelection={(days) => {
                  setReqRegionDays(days);
                  fetchTopRequestRegion(days);
                }}
                isHeader
              />
            )}
            {topConnected && <TopConnected data={topConnected} isHeader />}
          </Row>
          <Row>
            {activeRate && (
              <ActiveUserRate
                data={activeRate}
                selectedDays={activeDays}
                onDaysSelection={(days) => {
                  setActiveDays(days);
                  fetchActiveUserRate(days);
                }}
                isHeader
              />
            )}
            {newSubscribers && <NewSubscriber data={newSubscribers} isHeader />}
          </Row>
          <Row>
            {requestCountry && (
              <RequestByCountry
                data={requestCountry}
                selectedDays={reqCountryDays}
                onDaysSelection={(days) => {
                  setReqCountryDays(days);
                  fetchRequestCountries(days);
                }}
                isHeader
              />
            )}
            {requestRegion && (
              <RequestByRegion
                data={requestRegion}
                isHeader
                selectedDays={reqKoreaRegionDays}
                onDaysSelection={(days) => {
                  setKoreaReqRegionDays(days);
                  fetchRequestRegions(days);
                }}
              />
            )}
          </Row>
          <Row>
            {usageByFunction && (
              <UsageByFunction
                data={usageByFunction}
                selectedDays={usageByFuncDays}
                onDaysSelection={(days) => {
                  setUsageByFuncDays(days);
                  fetchUsageByFunction(days);
                }}
                isLoading={usageByFunctionLoading}
                isHeader
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
