import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Flatpickr from "react-flatpickr";
import { Card, CardBody, InputGroup } from "reactstrap";
import { CustomInputGroupText } from "../../../pages/Users/styled";

const RANGE_OPTIONS = [
  { value: 7, label: "7일" },
  { value: 15, label: "15일" },
  { value: 30, label: "한달" },
  { value: 60, label: "2개월" },
  { value: 90, label: "3개월" },
  { value: 180, label: "6개월" },
  { value: 365, label: "1년" },
];

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

type DateRangePickerProps = {
  initialDates: [string, string];
  initialRange: number;
  onDateChange: (dates: [string, string], range?: number) => void;
};

const DateRangePicker = ({
  initialDates,
  initialRange,
  onDateChange,
}: DateRangePickerProps) => {
  const flatpickrRef = useRef<null | Flatpickr>(null);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(initialDates);
  const [selectedRange, setSelectedRange] = useState<number>(initialRange);

  useEffect(() => {
    handleRangeSelection(initialRange);
  }, []);

  const handleIconClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  };

  const handleDateChange = (selectedDates: Date[]) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => formatDate(date)) as [
        string,
        string,
      ];
      setSelectedDates(formattedDates);
      onDateChange(formattedDates);
    }
  };

  const handleRangeSelection = (days: number) => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - days + 1); // Add 1 to include the current day
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const newDates: [string, string] = [formattedStartDate, formattedEndDate];
    setSelectedDates(newDates);
    setSelectedRange(days);
    onDateChange(newDates, days);
  };

  return (
    <Card>
      <CardBody>
        <InputGroup className="cursor-pointer">
          <Flatpickr
            ref={flatpickrRef}
            className="form-control d-block form-control-sm"
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
            }}
            value={selectedDates}
            onChange={handleDateChange}
          />
          <CustomInputGroupText
            className="custom-color"
            onClick={handleIconClick}
            id="CalendarTooltip"
          >
            <i className="ri-calendar-event-fill cursor-pointer"></i>
          </CustomInputGroupText>

          {RANGE_OPTIONS.map((option) => (
            <CustomInputGroupText
              key={option.value}
              className={classNames("custom-color", {
                activeBtn: selectedRange === option.value,
              })}
              onClick={() => handleRangeSelection(option.value)}
              id="CalendarTooltip"
            >
              {option.label}
            </CustomInputGroupText>
          ))}
        </InputGroup>
      </CardBody>
    </Card>
  );
};

export default DateRangePicker;
