import * as ApiBase from "@metamorp/api-base";
import { AddTerms, GetTermsList, Terms, TermsItem, UpdateTerms } from "./types";

const updateTermsConditions = (data: UpdateTerms) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, UpdateTerms>({
    method: "PUT",
    path: "/api/v1/terms/update",
    data: data,
  });
};

const getTermsConditionsList = (data: GetTermsList) => {
  type Response = ApiBase.Types.ApiListResBase<TermsItem>;
  return ApiBase.request<Response, GetTermsList>({
    method: "POST",
    path: "/api/v1/terms/list",
    data: data,
  });
};

const addTermsConditions = (data: AddTerms) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response, AddTerms>({
    method: "POST",
    path: "/api/v1/terms/add",
    data: data,
  });
};

const getTermsConditions = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<Terms>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/terms/${idx}`,
  });
};

const deleteTermsConditions = (idx: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<null>;
  return ApiBase.request<Response>({
    method: "DELETE",
    path: `/api/v1/terms/${idx}`,
  });
};

export default {
  /**
   * `PUT /api/v1/terms/update`
   *
   * 약관을 수정합니다.
   *
   * Update Terms & Conditions.
   */
  update: updateTermsConditions,
  /**
   * `POST /api/v1/terms/list`
   *
   * 약관 목록을 조회합니다.
   *
   * Retrieve a list of Terms & Conditions.
   */
  getList: getTermsConditionsList,
  /**
   * `POST /api/v1/terms/add`
   *
   * 약관을 추가합니다.
   *
   * Add Terms & Conditions.
   */
  add: addTermsConditions,
  /**
   * `GET /api/v1/terms/:idx`
   *
   * 약관을 조회합니다.
   *
   * Retrieve Terms & Conditions.
   */
  get: getTermsConditions,
  /**
   * `DELETE /api/v1/terms/:idx`
   *
   * 약관을 삭제합니다.
   *
   * Delete Terms & Conditions.
   */
  delete: deleteTermsConditions,
};
