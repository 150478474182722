import { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { Spinner } from "reactstrap";
import * as Api from "@/api";
import ChartBase from "./ChartBase";
import { calculateChartHeight, formatNumber } from "./utils";

type UsageByFunctionProps = {
  data: Api.Response.UsedFunction | null;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  isHeader?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
};

const UsageByFunction = ({
  data,
  selectedDays,
  onDaysSelection,
  isLoading,
  isHeader,
}: UsageByFunctionProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartRef.current || isLoading) return;

    const chartOptions = {
      series: [
        {
          name: "AI Preparation",
          data: data?.cnt0List || [],
        },
        {
          name: "슬라이싱",
          data: data?.cnt1List || [],
        },
        {
          name: "자동정렬",
          data: data?.cnt2List || [],
        },
        {
          name: "메쉬채우기",
          data: data?.cnt3List || [],
        },
        {
          name: "비우기",
          data: data?.cnt4List || [],
        },
        {
          name: "오프셋",
          data: data?.cnt5List || [],
        },
        {
          name: "복제",
          data: data?.cnt6List || [],
        },
        {
          name: "리메쉬",
          data: data?.cnt7List || [],
        },
        {
          name: "단순화",
          data: data?.cnt8List || [],
        },
        {
          name: "유니온",
          data: data?.cnt9List || [],
        },
        {
          name: "서브트랙트",
          data: data?.cnt10List || [],
        },
        {
          name: "인터섹터",
          data: data?.cnt11List || [],
        },
        {
          name: "서포트",
          data: data?.cnt12List || [],
        },
      ],
      chart: {
        type: "bar",
        // height: `${calculateChartHeight(data?.dateList.length)}px`,
        height: `${calculateChartHeight(data?.dateList?.length || 0)}px`,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          opacity: 0.1,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      colors: [
        "#5c67f7",
        "#e354d4",
        "#9e5cf7",
        "#fe5454",
        "#0ca3e7",
        "#f7a35c",
        "#54d48e",
        "#f5a3f7",
        "#478024",
        "#67a3f7",
        "#f7d354",
        "#54a3f7",
        "#0c9081",
      ],
      grid: {
        borderColor: "#f2f5f7",
      },
      xaxis: {
        categories: data?.dateList || [],
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#8c9097",
            fontSize: "11px",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return formatNumber(val) + "회";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: "top",
        fontSize: "10px",
        markers: {
          width: 7,
          height: 7,
        },
      },
    };

    // This is "actionChart"
    const chart = new ApexCharts(chartRef.current, chartOptions);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, isLoading]);

  return (
    <ChartBase
      title="기능별 사용빈도"
      tooltipId="funcUsageInfo"
      tooltipContent="선택기간동안 기능별 사용빈도입니다."
      selectedDays={selectedDays}
      onDaysSelection={onDaysSelection}
      dayOptions={[14, 30, 60]}
      redirectUrl="/usage-by-function"
      minHeight="25rem"
      isHeader={isHeader}
      cardBodyClass={isHeader ? "pt-0" : "pt-4"}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center position-absolute top-0 start-0 end-0 bottom-0">
          <Spinner className="me-2" color="secondary" />
        </div>
      )}
      <div ref={chartRef} style={{ width: "100%", height: "25rem" }}></div>
    </ChartBase>
  );
};

export default UsageByFunction;
