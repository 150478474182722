import { Input, Nav, Row } from "reactstrap";
import styled from "styled-components";

export const CustomNav = styled(Nav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomRow = styled(Row)`
  border-bottom: 1px dashed #e6e6e6;
  font-size: 13px;

  &:last-child {
    border-bottom: none;
  }
`;

export const CustomInput = styled(Input)`
  width: 1.125rem;
  height: 1.125rem;
`;
