import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import * as Api from "@/api";
import DeleteModal from "@/components/Common/DeleteModal";
import { useLocalizedMessage, useStateRef } from "@/helpers/hooks";
import { handleDeletePrinter } from "./utils";
import ControlPanel from "../../components/Common/ControlPanel";
import CustomTableContainer from "../../components/Common/CustomTableContainer";
import PageContainer from "../../components/Common/PageContainer";
import usePrinterStore from "../../zustandStore/printerStore";
import {
  ClickableCell,
  DisplayCell,
  ImageCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { REGISTRATION_OPTIONS, SHOW_OPTIONS } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Printer Management", link: "#" },
  { title: "List", link: "#" },
];

const Printer = () => {
  const { printers, setPrinters, loading, setLoading, setError } =
    usePrinterStore();
  const navigate = useNavigate();
  const location = useLocation();
  const getLocalizedMessage = useLocalizedMessage();
  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalPrinters, setTotalPrinters] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const searchKeywordRef = useStateRef(searchKeyword);
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [selectedRegType, setSelectedRegType] = useState(
    queryParams.regType || REGISTRATION_OPTIONS[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || SHOW_OPTIONS[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [printerToDelete, setPrinterToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/printer/${item.printerIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddPrinter = () => {
    navigate(`/printer/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      regType: selectedRegType,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    dateRange,
    searchKeyword,
    selectedRegType,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchPrinters = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedRegType,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Printer.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalPrinters(response.paging.totalRecord);
      setPrinters(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    selectedRegType,
    selectedShow,
    dateRange[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchPrinters();
  }, [fetchPrinters]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchPrinters();
  };

  const handleRegTypeSearch = (newRegType) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleShowSearch = (newShow) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedRegType(REGISTRATION_OPTIONS[0].value);
    setSelectedShow(SHOW_OPTIONS[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleIsModalOpen = (item) => {
    setPrinterToDelete(item);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    const printerIdx = printerToDelete.printerIdx;
    await handleDeletePrinter({
      printerIdx,
      navigate,
      setLoading,
      getLocalizedMessage,
    });
    setIsModalOpen(false);
    fetchPrinters();
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "printerIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => (
          <span style={{ color: value !== "메타몰프" ? "#5664d2" : "" }}>
            {value}
          </span>
        ),
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => (
          <ImageCell value={value} type="printer" />
        ),
      },
      {
        Header: "프린터명",
        accessor: "printerName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "제조사명",
        accessor: "manufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본프린터여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <RegTypeCell value={value} />,
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DisplayCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "선택",
        accessor: "select",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            color="danger"
            className="btn btn-sm waves-effect waves-light me-1 d-flex justify-content-center align-items-center"
            onClick={() => handleIsModalOpen(row.original)}
          >
            <i className="ri-delete-bin-line pe-1 " />
            삭제
          </Button>
        ),
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Printer Management"
    >
      <ControlPanel
        placeholder="프린터명, 제조사명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={dateRange}
        setDateRange={handleDateSearch}
        options1={REGISTRATION_OPTIONS}
        options2={SHOW_OPTIONS}
        selectedOption1={selectedRegType}
        setSelectedOption1={handleRegTypeSearch}
        selectedOption2={selectedShow}
        setSelectedOption2={handleShowSearch}
        selectTitle1="기본프린터여부"
        selectTitle2="표시여부"
      />
      <CustomTableContainer
        btnTitle="프린터 등록"
        sortByIdx="printerIdx"
        handleAddItem={handleAddPrinter}
        handleRowClick={handleRowClick}
        columns={columns || []}
        data={printers || []}
        isPagination={true}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalPrinters}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
      <DeleteModal
        isLoading={loading}
        data={printerToDelete?.printerName}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onHandleDelete={handleDelete}
      />
      <ToastContainer />
    </PageContainer>
  );
};

export default Printer;
