import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import useEmailTemplateStore from "@/zustandStore/emailTemplateStore";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import PageContainer from "../../components/Common/PageContainer";
import { ClickableCell } from "../Utility/CustomCellsType";

const BREADCRUMB_ITEMS = [
  { title: "Email Template", link: "#" },
  { title: "List", link: "#" },
];

const EmailTemplate = () => {
  const { emailTemplates, setEmailTemplates, loading, setLoading, setError } =
    useEmailTemplateStore();
  const navigate = useNavigate();
  const location = useLocation();

  const { pageSize, page, keyword, dateRange } = useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { tempIdx: number }) => {
      navigate(`/email-template/${item.tempIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddTemplate = () => {
    navigate(`/email-template/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    location.pathname,
    navigate,
  ]);

  const fetchEmailTemplates = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetTemplateList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };
      const response = await Api.EmailTemplate.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalTemplates(response.paging.totalRecord);
      setEmailTemplates(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error fetching email templates:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    customPageSize,
    searchKeywordRef,
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    fetchEmailTemplates();
  }, [fetchEmailTemplates]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchEmailTemplates();
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.EmailTemplateItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "tempIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "템플릿명",
        accessor: "title",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.EmailTemplateItem>) => (
          <ClickableCell
            value={value as string}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Email Template">
        <ControlPanelTypes
          placeholder="템플릿명, 등록자, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
        />
        <CustomTableContainerTypes
          btnTitle="템플릿 등록"
          sortByIdx="tempIdx"
          handleAddItem={handleAddTemplate}
          columns={columns || []}
          data={emailTemplates || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalTemplates}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default EmailTemplate;
