import classNames from "classnames";
import { Label } from "reactstrap";

type CustomLabelProps = {
  htmlFor: string;
  title: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
};

const CustomLabel = ({
  htmlFor,
  title,
  isRequired,
  isFullWidth,
}: CustomLabelProps) => {
  return (
    <Label
      htmlFor={htmlFor}
      className={classNames("col-md-2 col-form-label", {
        "w-100": isFullWidth,
      })}
    >
      {title}
      {isRequired && (
        <>
          (<span className="text-danger">*</span>)
        </>
      )}
    </Label>
  );
};

export default CustomLabel;
