import React from "react";
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { CustomModal } from "./styled";

const ConfirmModal = ({
  isLoading,
  isOpen,
  setIsOpen,
  action,
  handleSave,
  handleDelete,
  handleCopy,
}) => {
  const handleConfirm = () => {
    if (action === "save" || action === "edit") {
      handleSave();
    } else if (action === "copy") {
      handleCopy();
    } else if (action === "delete") {
      handleDelete();
    }
  };

  const getTitle = () => {
    switch (action) {
      case "save":
        return "등록하시겠습니까?";
      case "edit":
        return "수정하시겠습니까?";
      case "copy":
        return "복사하시겠습니까?";
      case "delete":
        return "삭제하시겠습니까?";
      default:
        return "";
    }
  };

  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          isOpen={isOpen}
          toggle={(prevState) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader>{getTitle()}</ModalHeader>
          <ModalBody>
            <p>
              {action === "delete"
                ? "이 작업은 되돌릴 수 없습니다."
                : "잘못된 정보가 없는지 주의해주세요."}
            </p>
          </ModalBody>

          <ModalFooter>
            {isLoading ? (
              <div className="d-flex flex-column align-items-center">
                <Spinner className="mb-3" color="secondary" />
              </div>
            ) : (
              <>
                <Button
                  type="button"
                  color="success"
                  className="waves-effect waves-light"
                  onClick={handleConfirm}
                  disabled={isLoading}
                >
                  확인
                </Button>
                <Button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  color="danger"
                  className="waves-effect"
                  disabled={isLoading}
                >
                  취소
                </Button>
              </>
            )}
          </ModalFooter>
        </CustomModal>
      </Col>
    </div>
  );
};

export default ConfirmModal;
