import { combineReducers } from "redux";

// Front

// Authentication Module
import Forget from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";

//Calendar
import Calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//ecommerce
import Ecommerce from "./e-commerce/reducer";
import Layout from "./layout/reducer";

const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Account,
  Login,
  Forget,
  Calendar,
  Ecommerce,
  chat,
});

export default rootReducer;
