import { ReactNode } from "react";
import * as Api from "@/api";

type CardConfig<T> = {
  countKey: keyof T;
  rateKey: keyof T;
  countLabel: string;
  rateLabel: string;
  icon: ReactNode;
  color: string;
};

export const REGISTER_CARD_CONFIGS: CardConfig<Api.Response.UserRegistration>[] =
  [
    {
      countKey: "totalUserCnt",
      rateKey: "yearGrowthRate",
      countLabel: "누적회원가입수",
      rateLabel: "전년대비",
      icon: <i className="ri-group-line fs-5"></i>,
      color: "bg-primary",
    },
    {
      countKey: "recentMonthCount",
      rateKey: "monthlyGrowthRate",
      countLabel: "월간가입수",
      rateLabel: "전월대비",
      icon: <i className="ri-group-line fs-5"></i>,
      color: "bg-primary",
    },
    {
      countKey: "recentWeekCount",
      rateKey: "weeklyGrowthRate",
      countLabel: "주간가입수",
      rateLabel: "전주대비",
      icon: <i className="ri-group-line fs-5"></i>,
      color: "bg-primary",
    },
  ];

export const CONNECTION_CARD_CONFIGS: CardConfig<Api.Response.UserConnection>[] =
  [
    {
      countKey: "recentMonthCount",
      rateKey: "monthlyGrowthRate",
      countLabel: "월간접속수",
      rateLabel: "전월대비",
      icon: <i className="ri-lock-line fs-5"></i>,
      color: "bg-primary1",
    },
    {
      countKey: "recentWeekCount",
      rateKey: "weeklyGrowthRate",
      countLabel: "주간접속수",
      rateLabel: "전주대비",
      icon: <i className="ri-lock-line fs-5"></i>,
      color: "bg-primary1",
    },
    {
      countKey: "recentDayCount",
      rateKey: "dailyGrowthRate",
      countLabel: "일간접속수",
      rateLabel: "전일대비",
      icon: <i className="ri-lock-line fs-5"></i>,
      color: "bg-primary1",
    },
  ];

export const AI_REQUEST_CARD_CONFIGS: CardConfig<Api.Response.UserAiRequest>[] =
  [
    {
      countKey: "recentMonthCount",
      rateKey: "monthlyGrowthRate",
      countLabel: "월간AI요청수",
      rateLabel: "전월대비",
      icon: <span className="fs-6">AI</span>,
      color: "bg-primary2",
    },
    {
      countKey: "recentWeekCount",
      rateKey: "weeklyGrowthRate",
      countLabel: "주간AI요청수",
      rateLabel: "전주대비",
      icon: <span className="fs-6">AI</span>,

      color: "bg-primary2",
    },
    {
      countKey: "recentDayCount",
      rateKey: "dailyGrowthRate",
      countLabel: "일간AI요청수",
      rateLabel: "전일대비",
      icon: <span className="fs-6">AI</span>,
      color: "bg-primary2",
    },
  ];

export const API_REQUEST_CARD_CONFIGS: CardConfig<Api.Response.UserApiRequest>[] =
  [
    {
      countKey: "recentMonthCount",
      rateKey: "monthlyGrowthRate",
      countLabel: "월간API요청수",
      rateLabel: "전월대비",
      icon: <span className="fs-6">API</span>,
      color: "bg-primary3",
    },
    {
      countKey: "recentWeekCount",
      rateKey: "weeklyGrowthRate",
      countLabel: "주간API요청수",
      rateLabel: "전주대비",
      icon: <span className="fs-6">API</span>,
      color: "bg-primary3",
    },
    {
      countKey: "recentDayCount",
      rateKey: "dailyGrowthRate",
      countLabel: "일간API요청수",
      rateLabel: "전일대비",
      icon: <span className="fs-6">API</span>,
      color: "bg-primary3",
    },
  ];
