import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomFileInput from "@/components/Common/CustomFileInput";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import Selector from "@/components/Profile/Selector";
import { useLocalizedMessage } from "@/helpers/hooks";
import { OptionProps } from "@/pages/AppEquipment";
import CustomCkEditor from "@/pages/EmailTemplate/components/CustomCkEditor";
import { CardImgContainer, HoverImage } from "@/pages/Utility/styled";

type NoticeFormProps = {
  isDetailView: boolean;
};

type NoticeProps = Api.Request.AddNotice &
  Partial<Pick<Api.Response.Notice, "boardIdx" | "topImgPath" | "thumImgPath">>;

const DEFAULT_NOTICE: NoticeProps = {
  cateIdx: 0,
  title: "",
  subTitle: "",
  content: "",
  authorName: "",
  displayFlag: 1,
  thumImgFile: null,
  topImgFile: null,
};

const NoticeForm = ({ isDetailView }: NoticeFormProps) => {
  const breadcrumbItems = [
    { title: "Notice Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();
  const { boardIdx } = useParams();

  const [notice, setNotice] = useState(DEFAULT_NOTICE);
  const [thumbnailImgName, setThumbnailImgName] = useState("선택된 파일 없음");
  const [topImgName, setTopImgName] = useState("선택된 파일 없음");

  const [categories, setCategories] = useState<OptionProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const getNoticeByIdx = useCallback(async () => {
    if (!boardIdx) return;
    try {
      const response = await Api.Notice.get(parseInt(boardIdx, 10));
      const existingNotice = response.data;
      if (existingNotice) {
        setNotice(existingNotice);
      }
    } catch (error) {
      console.error("Error fetching Notice from API", error);
    }
  }, [boardIdx]);

  useEffect(() => {
    if (isDetailView) {
      getNoticeByIdx();
    }
  }, [getNoticeByIdx, isDetailView]);

  useEffect(() => {
    const getCategories = async () => {
      const response = await Api.Category.getList();
      const categories: Api.Response.CategoryItem[] = response?.data;
      if (categories.length === 0) return;
      const modifiedCategories = categories.map((category) => ({
        label: category.names.ko,
        value: category.cateIdx,
      }));
      setCategories(modifiedCategories);
      setNotice((prev) => ({ ...prev, cateIdx: categories[0].cateIdx }));
    };

    getCategories();
  }, []);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
      checked?: boolean;
      files?: FileList | null;
    };
  }) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (newValue instanceof File) {
      if (name === "thumbnailImgName") {
        setThumbnailImgName(newValue?.name);
      } else if (name === "topImgName") {
        setTopImgName(newValue?.name);
      }
    }
    setNotice((prevVer) => ({ ...prevVer, [name]: newValue }));
  };

  const handleSaveNotice = async () => {
    if (
      !notice.authorName ||
      !notice.content ||
      !notice.subTitle ||
      (!isDetailView && !notice.thumImgFile) ||
      (!isDetailView && !notice.topImgFile)
    ) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }
    setIsLoading(true);

    try {
      let response;
      const requestData = {
        cateIdx: notice.cateIdx,
        title: notice.title,
        subTitle: notice.subTitle,
        content: notice.content,
        authorName: notice.authorName,
        displayFlag: notice.displayFlag,
        thumImgFile: notice.thumImgFile,
        topImgFile: notice.topImgFile,
      };

      if (isDetailView) {
        if (typeof notice?.boardIdx !== "number") return;
        response = await Api.Notice.update({
          ...requestData,
          boardIdx: notice.boardIdx,
        });
      } else {
        response = await Api.Notice.add(requestData);
      }

      const successMessage = getLocalizedMessage(response, "success");

      setIsOpen(false);
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/notice-board${location.search}`);
        } else {
          navigate("/notice-board");
        }
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error: adding new notice", error);
        const errMessage = getLocalizedMessage(error, "error");
        toast.error(errMessage, {
          autoClose: 3000,
        });
      } else {
        console.error("Unexpected error", error);
        const errMessage = getLocalizedMessage(error, "error");
        toast.error(errMessage, {
          autoClose: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/notice-board${location.search}`);
  };

  const handleDeleteNotice = async () => {
    if (!notice.boardIdx) return;
    setIsLoading(true);
    try {
      const response = await Api.Notice.delete(notice?.boardIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/notice-board");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer breadcrumbItems={breadcrumbItems} title="Notice Management">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">게시물정보를 입력해주세요.</h4>
              <AvForm className="mt-4">
                <Row className="mb-3">
                  <Selector
                    label="카테고리"
                    id="cateIdx"
                    options={categories}
                    value={notice.cateIdx}
                    onChange={handleChange}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="title"
                    label="제목"
                    placeholder="제목을 입력해주세요"
                    type="text"
                    value={notice.title || ""}
                    onChange={handleChange}
                    initialErrorMessage="제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="subTitle"
                    label="부제목"
                    placeholder="부제목을 입력해주세요"
                    type="text"
                    value={notice.subTitle || ""}
                    onChange={handleChange}
                    initialErrorMessage="부제목을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <CustomLabel htmlFor="content" title="내용" isRequired />
                  <Col>
                    <CustomCkEditor
                      id="content"
                      data={notice.content || ""}
                      isFocused={isEditorFocused}
                      handleFocus={() => setIsEditorFocused(true)}
                      handleBlur={() => setIsEditorFocused(false)}
                      onChange={(content: string) =>
                        setNotice((prev) => ({ ...prev, content }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <FormInputTypes
                    id="authorName"
                    label="등록자명"
                    placeholder="등록자명을 입력해주세요"
                    type="text"
                    value={notice.authorName || ""}
                    onChange={handleChange}
                    initialErrorMessage="등록자명을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="thumImgFile"
                    title="썸네일이미지"
                    isRequired
                  />
                  <CustomFileInput
                    name="thumImgFile"
                    fileName={notice.thumImgFile?.name || thumbnailImgName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !notice.thumImgFile && notice.thumImgPath && (
                  <Row className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        style={{ marginLeft: "4px", width: "auto" }}
                        src={notice?.thumImgPath}
                        alt=""
                        className="rounded avatar-lg"
                      />
                      <HoverImage
                        style={{
                          backgroundImage: `url(${notice?.thumImgPath})`,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="topImgFile"
                    title="상단이미지"
                    isRequired
                  />
                  <CustomFileInput
                    name="topImgFile"
                    fileName={notice.topImgFile?.name || topImgName}
                    onChange={handleChange}
                  />
                </Row>
                {isDetailView && !notice.topImgFile && notice.topImgPath && (
                  <Row className="mb-2">
                    <Col>
                      <Label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      />
                      <CardImgContainer
                        style={{ marginLeft: "4px", width: "auto" }}
                        src={notice?.topImgPath}
                        alt=""
                        className="rounded avatar-lg"
                      />
                      <HoverImage
                        style={{
                          backgroundImage: `url(${notice?.topImgPath})`,
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mb-2">
                  <ToggleSwitch
                    label="표시여부"
                    id="displayFlag"
                    checked={notice.displayFlag === 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setNotice({ ...notice, displayFlag: 1 });
                      } else {
                        setNotice({ ...notice, displayFlag: 0 });
                      }
                    }}
                    extraClass="mt-2"
                  />
                </Row>
                <Row>
                  <FormButtonsTypes
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                </Row>
                <ConfirmModalTypes
                  isLoading={isLoading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSaveNotice}
                  handleDelete={handleDeleteNotice}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default NoticeForm;
