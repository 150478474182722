import * as ApiBase from "@metamorp/api-base";
import {
  ActiveRate,
  ApiRequestState,
  ConnectionState,
  NewSubscriber,
  RegistrationState,
  RequestCountry,
  RequestRegion,
  TopConnectedUser,
  TopRequestModel,
  TopRequestRegion,
  UsedFunction,
  UserAiRequest,
  UserApiRequest,
  UserConnection,
  UserRegistration,
} from "./types";

const getUserRegistration = () => {
  type Response = ApiBase.Types.ApiStandardResBase<UserRegistration>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/join/card",
  });
};

const getUserConnection = () => {
  type Response = ApiBase.Types.ApiStandardResBase<UserConnection>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/connect/card",
  });
};

const getApiData = () => {
  type Response = ApiBase.Types.ApiStandardResBase<UserApiRequest>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/api/card",
  });
};

const getAiData = () => {
  type Response = ApiBase.Types.ApiStandardResBase<UserAiRequest>;
  return ApiBase.request<Response>({
    method: "GET",
    path: "/api/v1/dashboard/ai/card",
  });
};

const getConnectionState = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<ConnectionState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/connect/state/${days}`,
  });
};

const getRegistrationState = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<RegistrationState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/join/state/${days}`,
  });
};

const getApiRequestState = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<ApiRequestState>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/api/state/${days}`,
  });
};

const getTopRequestModel = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<TopRequestModel>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/model/state/${days}`,
  });
};

const getTopRequestRegion = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<TopRequestRegion>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/country/state/${days}`,
  });
};

const getTopConnectedUser = () => {
  type Response = ApiBase.Types.ApiListResBase<TopConnectedUser>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/connected`,
  });
};

const getActiveUserRate = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<ActiveRate>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/active/state/${days}`,
  });
};

const getNewSubscribers = () => {
  type Response = ApiBase.Types.ApiListResBase<NewSubscriber>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/top/join`,
  });
};

const getRequestByCountry = (days: number) => {
  type Response = ApiBase.Types.ApiListResBase<RequestCountry>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/req/country/state/${days}`,
  });
};

const getRequestByRegion = (days: number) => {
  type Response = ApiBase.Types.ApiListResBase<RequestRegion>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/req/korea/state/${days}`,
  });
};

const getUseByFunction = (days: number) => {
  type Response = ApiBase.Types.ApiStandardResBase<UsedFunction>;
  return ApiBase.request<Response>({
    method: "GET",
    path: `/api/v1/dashboard/action/state/${days}`,
  });
};

export default {
  /**
   * `GET /api/v1/dashboard/join/card`
   *
   * @authorization-required
   *
   * Retrieve user registration data.
   */
  getUserRegistration: getUserRegistration,
  /**
   * `GET api/v1/dashboard/connect/card`
   *
   * @authorization-required
   *
   * Retrieve user connection data.
   */
  getUserConnection: getUserConnection,
  /**
   * `GET api/v1/dashboard/api/card`
   *
   * @authorization-required
   *
   * Retrieve API request data.
   */
  getApiData: getApiData,
  /**
   * `GET /api/v1/dashboard/connect/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve AI request data.
   */
  getAiData: getAiData,
  /**
   * `GET api/v1/dashboard/connect/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Connection state data.
   */
  getConnectionState: getConnectionState,
  /**
   * `GET api/v1/dashboard/join/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Registration state data.
   */
  getRegistrationState: getRegistrationState,
  /**
   * `GET api/v1/dashboard/api/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve API Request state data.
   */
  getApiRequestState: getApiRequestState,
  /**
   * `GET api/v1/dashboard/model/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Top Request Model data.
   */
  getTopRequestModel: getTopRequestModel,
  /**
   * `GET api/v1/dashboard/top/country/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Top Request Region data.
   */
  getTopRequestRegion: getTopRequestRegion,
  /**
   * `GET api/v1/dashboard/top/connected`
   *
   * @authorization-required
   *
   * Retrieve Top Connected Users data.
   */
  getTopConnectedUser: getTopConnectedUser,
  /**
   * `GET api/v1/dashboard/active/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve App Active User Rate data.
   */
  getActiveUserRate: getActiveUserRate,
  /**
   * `GET /api/v1/dashboard/top/join`
   *
   * @authorization-required
   *
   * Retrieve Recently Joined Users data.
   */
  getNewSubscribers: getNewSubscribers,
  /**
   * `GET api/v1/dashboard/req/country/state/%{days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Country data.
   */
  getRequestByCountry: getRequestByCountry,
  /**
   * `GET api/v1/dashboard/req/korea/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Requests by Region data.
   */
  getRequestByRegion: getRequestByRegion,
  /**
   * `GET api/v1/dashboard/action/state/${days}`
   *
   * @authorization-required
   *
   * Retrieve Frequency of use by function data.
   */
  getUseByFunction: getUseByFunction,
};
