import { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { toast, ToastContainer } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import * as Api from "@/api";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import useQueryParams from "@/helpers/hooks";
import CheckboxInput from "./CheckboxInput";
import { CustomRow } from "./styled";

type TargetModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setEmail: React.Dispatch<React.SetStateAction<Api.Request.SendEmail>>;
};

export const JOB_TYPE_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "치과의사", value: 1 },
  { label: "치과기공사", value: 2 },
  { label: "그외", value: 3 },
];

export const RECEPTION_TYPE_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "뉴스수신", value: 1 },
  { label: "광고수신", value: 2 },
];

export const COUNTRY_TYPE_OPTIONS = [
  { label: "전체", value: -1 },
  { label: "한국", value: 1 },
  { label: "일본", value: 2 },
  { label: "중국", value: 3 },
  { label: "미국", value: 4 },
  { label: "러시아", value: 5 },
  { label: "그외", value: 6 },
];

type TargetProps = {
  jobType: number[];
  receptionType: number[];
  countryType: number[];
};

const TargetModal = ({ isOpen, setIsOpen, setEmail }: TargetModalProps) => {
  const navigate = useNavigate();
  const { pageSize, page } = useQueryParams();

  const [target, setTarget] = useState<TargetProps>({
    jobType: [-1],
    receptionType: [-1],
    countryType: [-1],
  });
  const [targetUsers, setTargetUsers] = useState<Api.Response.TargetItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalTargetUsers, setTotalTargetUsers] = useState(0);

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      jobType: target.jobType,
      receptionType: target.receptionType,
      countryType: target.countryType,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [currentPage, customPageSize, target]);

  useEffect(() => {
    if (isOpen) {
      setTarget({
        jobType: [-1],
        receptionType: [-1],
        countryType: [-1],
      });
      setSelectedIds([]);
      setSelectedEmails([]);
      setCurrentPage(0);
    }
  }, [isOpen]);

  // fetch users according control panel settings like jobType, countryType, page etc..
  const fetchTargetUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const requestData: Api.Request.GetTargetList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        jobList: target.jobType.includes(-1)
          ? undefined
          : target.jobType.map((jobType) => ({ jobType })),
        receptionList: target.receptionType.includes(-1)
          ? undefined
          : target.receptionType.map((receptionType) => ({ receptionType })),
        countryList: target.countryType.includes(-1)
          ? undefined
          : target.countryType.map((countryType) => ({ countryType })),
      };

      const response = await Api.Emailing.getTarget(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalTargetUsers(response.paging.totalRecord);
      setTargetUsers(response.data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error fetching target list", error);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customPageSize, target]);

  useEffect(() => {
    fetchTargetUsers();
  }, [fetchTargetUsers]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handlePanelCheckboxChange = (
    checked: boolean,
    value: number,
    name: keyof TargetProps,
  ) => {
    setTarget((prevTarget) => {
      const currentList = prevTarget[name];
      let newList: number[];

      if (value === -1) {
        // Handle "전체" checkbox
        newList = checked ? [-1] : [getFirstNonAllValue(name)];
      } else {
        if (checked) {
          // Add the value and remove -1 if it exists
          newList = [...currentList.filter((v) => v !== -1), value];
        } else {
          // Remove the value
          newList = currentList.filter((v) => v !== value);
          // If no checkboxes are selected, check the first non-all value
          if (newList.length === 0) {
            newList = [getFirstNonAllValue(name)];
          }
        }
      }

      return {
        ...prevTarget,
        [name]: newList,
      };
    });
    setCurrentPage(0);
  };

  // Get the first non-all value for each type when unselect All value
  const getFirstNonAllValue = (type: keyof TargetProps) => {
    switch (type) {
      case "jobType":
        return JOB_TYPE_OPTIONS[1].value;
      case "receptionType":
        return RECEPTION_TYPE_OPTIONS[1].value;
      case "countryType":
        return COUNTRY_TYPE_OPTIONS[1].value;
      default:
        return -1;
    }
  };

  // Check if a checkbox should be disabled
  const isCheckboxDisabled = (type: keyof TargetProps, value: number) => {
    const currentList = target[type];
    return value !== -1 && currentList.includes(-1);
  };

  const handleCheckAll = useCallback(() => {
    const currentPageIds = targetUsers.map((user) => user.userIdx);
    const currentPageEmails = targetUsers.map((user) => user.userId);

    const shouldSelectAll = !targetUsers.every((user) =>
      selectedIds.includes(user.userIdx),
    );

    if (shouldSelectAll) {
      // Add all current page users to selection
      setSelectedIds((prev) => [
        ...prev,
        ...currentPageIds.filter((id) => !prev.includes(id)),
      ]);

      setSelectedEmails((prev) => [
        ...prev,
        ...currentPageEmails.filter((email) => !prev.includes(email)),
      ]);
    } else {
      // Remove all current page users from selection
      setSelectedIds((prev) =>
        prev.filter((id) => !currentPageIds.includes(id)),
      );

      setSelectedEmails((prev) =>
        prev.filter((email) => !currentPageEmails.includes(email)),
      );
    }
  }, [targetUsers, selectedIds]);

  const handleCheckboxChange = useCallback(
    (userIdx: number) => {
      const user = targetUsers.find((user) => user.userIdx === userIdx);
      if (user) {
        setSelectedIds((prev) =>
          prev.includes(userIdx)
            ? prev.filter((id) => id !== userIdx)
            : [...prev, userIdx],
        );

        setSelectedEmails((prevEmails) =>
          prevEmails.includes(user.userId)
            ? prevEmails.filter((email) => email !== user.userId)
            : [...prevEmails, user.userId],
        );
      }
    },
    [targetUsers],
  );

  // set selected emails to targetEmails
  const handleAddSelected = () => {
    if (selectedEmails.length === 0) {
      toast.error("먼저 전송대상을 선택해주세요", { autoClose: 3000 });
    } else {
      setEmail((prevState) => ({
        ...prevState,
        targetEmails: selectedEmails.join(","),
      }));
      setIsOpen(false);
    }
  };

  // get a list of ALL Emails and set them to targetEmails
  const fetchAllTargetEmails = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        jobList: target.jobType.includes(-1)
          ? undefined
          : target.jobType.map((jobType) => ({ jobType })),
        receptionList: target.receptionType.includes(-1)
          ? undefined
          : target.receptionType.map((receptionType) => ({ receptionType })),
        countryList: target.countryType.includes(-1)
          ? undefined
          : target.countryType.map((countryType) => ({ countryType })),
      };
      const response = await Api.Emailing.getTargetIds(requestData);
      if (response.data.length > 0) {
        setEmail((prevState) => ({
          ...prevState,
          targetEmails: response.data.join(","),
        }));
        setIsOpen(false);
      }
    } catch (error) {
      console.error("Failed fetching Target Emails", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: CustomColumn<Api.Response.TargetItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "userIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "ID",
        accessor: "userId",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "닉네임",
        accessor: "nickName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "직업",
        accessor: "job",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "전화번호",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "국가",
        accessor: "countryName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "광고수신",
        accessor: "newsAgree",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.TargetItem>) => (
          <span>{value ? "예" : "아니오"}</span>
        ),
      },
      {
        Header: "뉴스수신",
        accessor: "adAgree",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.TargetItem>) => (
          <span>{value ? "예" : "아니오"}</span>
        ),
      },
    ],
    [],
  );

  return (
    <Modal size="xl" isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>전송대상찾기</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CustomRow className="">
                  <Col xl="2" md="2">
                    <Label className="col-md-2 col-form-label w-100 fw-normal">
                      직업군
                    </Label>
                  </Col>
                  <Col
                    xl="10"
                    md="10"
                    className="d-flex align-items-center gap-2"
                  >
                    {JOB_TYPE_OPTIONS.map((job) => (
                      <CheckboxInput
                        key={job.value}
                        value={job.value}
                        checked={target.jobType.includes(job.value)}
                        id={`checkbox-job-${job.value}`}
                        name="jobType"
                        label={job.label}
                        onHandleChange={(checked, value) =>
                          handlePanelCheckboxChange(checked, value, "jobType")
                        }
                        disabled={isCheckboxDisabled("jobType", job.value)}
                      />
                    ))}
                  </Col>
                </CustomRow>
                <CustomRow className="">
                  <Col xl="2" md="2">
                    <Label className="col-md-2 col-form-label w-100 fw-normal">
                      수신설정
                    </Label>
                  </Col>
                  <Col
                    xl="10"
                    md="10"
                    className="d-flex align-items-center gap-2"
                  >
                    {RECEPTION_TYPE_OPTIONS.map((reception) => (
                      <CheckboxInput
                        key={reception.value}
                        value={reception.value}
                        checked={target.receptionType.includes(reception.value)}
                        id={`checkbox-reception-${reception.value}`}
                        name="receptionType"
                        label={reception.label}
                        onHandleChange={(checked, value) =>
                          handlePanelCheckboxChange(
                            checked,
                            value,
                            "receptionType",
                          )
                        }
                        disabled={isCheckboxDisabled(
                          "receptionType",
                          reception.value,
                        )}
                      />
                    ))}
                  </Col>
                </CustomRow>
                <CustomRow className="">
                  <Col xl="2" md="2">
                    <Label className="col-md-2 col-form-label w-100 fw-normal">
                      국가
                    </Label>
                  </Col>
                  <Col
                    xl="10"
                    md="10"
                    className="d-flex align-items-center gap-2"
                  >
                    {COUNTRY_TYPE_OPTIONS.map((country) => (
                      <CheckboxInput
                        key={country.value}
                        value={country.value}
                        checked={target.countryType.includes(country.value)}
                        id={`checkbox-country-${country.value}`}
                        name="countryType"
                        label={country.label}
                        onHandleChange={(checked, value) =>
                          handlePanelCheckboxChange(
                            checked,
                            value,
                            "countryType",
                          )
                        }
                        disabled={isCheckboxDisabled(
                          "countryType",
                          country.value,
                        )}
                      />
                    ))}
                  </Col>
                </CustomRow>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <CustomTableContainerTypes
            btnTitle="전체대상등록"
            btnTitle1="선택대상등록"
            btnIcon=""
            sortByIdx="userIdx"
            handleAddItem={fetchAllTargetEmails}
            handleBtn1={handleAddSelected}
            columns={columns || []}
            data={targetUsers || []}
            customPageSize={customPageSize}
            totalPage={totalPage}
            totalRecord={totalTargetUsers}
            setCustomPageSize={setCustomPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            selectedIds={selectedIds}
            handleCheckboxChange={handleCheckboxChange}
            handleCheckAll={handleCheckAll}
            showCheckboxColumn={true}
            className="custom-header-css table align-middle table-nowrap"
            tableClassName="table-centered align-middle table-nowrap mb-0"
            theadClassName="text-muted table-light"
          />
        </Row>
      </ModalBody>
      <ToastContainer />
    </Modal>
  );
};

export default TargetModal;
