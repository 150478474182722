import { create } from "zustand";

export type AppEquipmentProps = {
  clientIdx?: number | undefined;
  registrationId?: string | null;
  appIdx: number;
  clientName: string;
  appId: string;
  appName: string;
  stateFlag: number;
  connectionFlag: number;
  reqCnt: number;
  regName: string;
  regId: string;
  regIdx: number;
  regDt: string;
  updName: string | null;
  updId: string | null;
  updIdx: number | null;
  updDt: string | null;
  checked?: boolean;
};

type AppEquipmentState = {
  appEquipments: AppEquipmentProps[];
  loading: boolean;
  error: string | null;
  setAppEquipments: (appEquipments: AppEquipmentProps[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useAppEquipmentStore = create<AppEquipmentState>((set) => ({
  appEquipments: [],
  loading: false,
  error: null,
  setAppEquipments: (appEquipments) => set({ appEquipments }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useAppEquipmentStore;
