import * as ApiBase from "@metamorp/api-base";
import { Authenticate, Authentication, Otp, RequestOtp } from "./types";

const loginAdmin = (data: Authenticate) => {
  type Response = ApiBase.Types.ApiStandardResBase<Authentication>;
  return ApiBase.request<Response, Authenticate>({
    method: "POST",
    path: "/api/v1/login",
    data: data,
  });
};

const requestOtp = (data: RequestOtp) => {
  type Response = ApiBase.Types.ApiStandardResBase<Otp>;
  return ApiBase.request<Response, RequestOtp>({
    method: "POST",
    path: "/api/v1/login/otp",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/login`
   *
   * 관리자 계정을 인증하고 엑세스 토큰을 발급합니다.
   *
   * Authenticate the admin account and issue an access token.
   */
  login: loginAdmin,
  /**
   * `POST /api/v1/login/otp`
   *
   * 관리자 계정 로그인 OTP 번호 발급을 요청합니다.
   *
   * Request issuance of OTP number for admin account login.
   */
  requestOtp: requestOtp,
};
