import {
  Button,
  Col,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import * as Api from "@/api";
import { TableData } from "./styles";
import { CustomModal } from "../../../components/Common/styled";

type VersionChangesProps = {
  title: string;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data: Api.Response.SlicingItem | null;
};

const FilesModal = ({
  title,
  isLoading,
  isOpen,
  setIsOpen,
  data,
}: VersionChangesProps) => {
  const handleDownload = (reqUrl: string) => {
    window.location.href = reqUrl;
  };

  return (
    <div>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <CustomModal
          isOpen={isOpen}
          toggle={(prevState: boolean) => setIsOpen(!prevState)}
          centered={true}
        >
          <ModalHeader>
            {title}
            <Button
              type="button"
              className="btn-close btn-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            ></Button>
          </ModalHeader>

          <ModalBody>
            {isLoading ? (
              <Spinner className="me-2" color="secondary" />
            ) : (
              <Col lg={12}>
                <div className="table-responsive">
                  {data?.reqFileList && data.reqFileList?.length > 0 ? (
                    <Table className="mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>요청 파일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.reqFileList?.map((link, idx) => (
                          <tr key={link.sfIdx}>
                            <th scope="row">{idx + 1}</th>
                            <td>{link.reqFileUrl.split("/").pop()}</td>
                            <TableData
                              onClick={() => handleDownload(link.reqFileUrl)}
                            >
                              다운로드
                              <i className="ri-download-line" />
                            </TableData>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-center my-2">다운로드 파일 없습니다</p>
                  )}
                </div>
              </Col>
            )}
          </ModalBody>
        </CustomModal>
      </Col>
    </div>
  );
};

export default FilesModal;
