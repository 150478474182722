import React, { useEffect, useState } from "react";

// Layout Related Components
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import useLayoutStore from "../../zustandStore/layoutStore";
import Rightbar from "../CommonForBoth/Rightbar";

const Layout = ({ children }) => {
  const location = useLocation();
  // const [isMobile, setIsMobile] = useState(
  //   /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  // );

  const {
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    topbarTheme,
    theme,
    isPreloader,
    showRightSidebar,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutTheme,
    toggleRightSidebar,
    isMobile,
    rightSidebarVisible,
  } = useLayoutStore();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    let currentage = capitalizeFirstLetter(location.pathname);
    currentage = currentage.replaceAll("-", " ");
    document.title = `${currentage} | Metamorp - Admin Dashboard`;

    if (leftSideBarTheme) {
      changeSidebarTheme(leftSideBarTheme);
    }

    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }

    if (leftSideBarType) {
      changeSidebarType(leftSideBarType);
    }

    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }

    if (theme) {
      changeLayoutTheme(theme);
    }

    // if (showRightSidebar) {
    //   toggleRightSidebar();
    // }

    if (isPreloader) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(() => {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [
    location,
    leftSideBarTheme,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    theme,
    showRightSidebar,
    isPreloader,
    toggleRightSidebar,
  ]);

  const toggleRightbar = () => {
    toggleRightSidebar();
  };

  const toggleMenuCallback = () => {
    const { leftSideBarType, changeSidebarType, isMobile } =
      useLayoutStore.getState();

    if (leftSideBarType === "default") {
      changeSidebarType("condensed", isMobile);
    } else if (leftSideBarType === "condensed") {
      changeSidebarType("default", isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="ri-loader-line spin-icon"></i>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          toggleRightSidebar={toggleRightbar}
        />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {children}
          {/* <Footer /> */}
        </div>
      </div>
      {rightSidebarVisible && <Rightbar />}
    </React.Fragment>
  );
};

export default Layout;
