import { useNavigate } from "react-router-dom";
import { DashboardTooltip } from "../styled";

export type CardHeaderProps = {
  title: string;
  tooltipId: string;
  tooltipContent: string;
  isHovered: boolean;
  setIsHovered: () => void;
  selectedDays?: number;
  onDaysSelection?: (days: number) => void;
  dayOptions?: number[];
  redirectUrl?: string;
  onClick?: () => void;
};

const CardHeader = ({
  title,
  tooltipId,
  tooltipContent,
  isHovered,
  setIsHovered,
  selectedDays,
  onDaysSelection,
  dayOptions,
  redirectUrl,
  onClick,
}: CardHeaderProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick?.();
    if (redirectUrl) navigate(redirectUrl);
  };

  return (
    <div className="card-header justify-content-between">
      <div className="card-title">
        {title}{" "}
        <i
          className="ri-question-line "
          id={tooltipId}
          style={{ color: "#6e829f" }}
        ></i>
        <DashboardTooltip
          placement="top"
          isOpen={isHovered}
          target={tooltipId}
          toggle={setIsHovered}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10], // Moves the tooltip 10px higher
              },
            },
          ]}
        >
          <span dangerouslySetInnerHTML={{ __html: tooltipContent }} />
        </DashboardTooltip>
      </div>
      <div className="d-flex gap-2">
        {dayOptions &&
          dayOptions.map((day) => (
            <div
              key={day}
              className={`btn ${
                selectedDays === day ? "btn-light" : "btn-outline-light"
              } border btn-full btn-sm`}
              onClick={() => onDaysSelection?.(day)}
            >
              {day}일
            </div>
          ))}
        <div
          className="btn btn-outline-light border btn-full btn-sm d-flex align-items-center "
          onClick={handleClick}
        >
          <i className="ri-add-line me-1"></i>
          더보기
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
