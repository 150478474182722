type EmailPreviewProps = {
  initialTemplate: string;
};

const EmailPreview = ({ initialTemplate }: EmailPreviewProps) => {
  return (
    <div className="border p-3 mb-3 rounded" style={{ minHeight: "300px" }}>
      <div
        dangerouslySetInnerHTML={{
          __html: initialTemplate,
        }}
        className="ck-content"
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      />
    </div>
  );
};

export default EmailPreview;
