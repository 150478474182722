import * as ApiBase from "@metamorp/api-base";
import { GetProjectList, ProjectItem } from "./types";

const getProjectList = (data: GetProjectList) => {
  type Response = ApiBase.Types.ApiListResBase<ProjectItem>;
  return ApiBase.request<Response, GetProjectList>({
    method: "POST",
    path: "/api/v1/project/list",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/project/list`
   *
   * 프로젝트 파일 목록을 조회합니다.
   *
   * Retrieve a list of project files.
   */
  getList: getProjectList,
};
