type CardProps = {
  count: number;
  rate: number;
  countLabel: string;
  rateLabel: string;
  icon: React.ReactNode;
  color: string;
  isRatePositive: boolean;
};

const Card = ({
  count,
  rate,
  countLabel,
  rateLabel,
  icon,
  color,
  isRatePositive,
}: CardProps) => {
  return (
    <div className="card custom-card overflow-hidden main-content-card">
      <div className="card-body">
        <div className="d-flex align-items-start justify-content-between mb-2">
          <div>
            <span className="text-muted d-block mb-1 fs-13 fw-bold">
              {countLabel}
            </span>{" "}
            <h4 className="fw-medium mb-0">{count}</h4>
          </div>
          <div className="lh-1">
            <span className={`avatar avatar-md avatar-rounded ${color}`}>
              {" "}
              {icon}
            </span>
          </div>
        </div>
        <div className="text-muted fs-13 fw-medium">
          {rateLabel}{" "}
          <span
            className={`fw-bold ${
              isRatePositive ? "text-success" : "text-danger"
            }`}
          >
            {rate}%
            {isRatePositive ? (
              <i className="ri-arrow-up-line"></i>
            ) : (
              <i className="ri-arrow-down-line"></i>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;
