import * as ApiBase from "@metamorp/api-base";
import { TopUserRanking } from "./type";
import { DateRange } from "../common/type";

const getTopUserRanking = (data: DateRange) => {
  type Response = ApiBase.Types.ApiListResBase<TopUserRanking>;
  return ApiBase.request<Response>({
    method: "POST",
    path: `/api/v1/statis/top/connected`,
    data: data,
  });
};

export default {
  /**
   * `GET /api/v1/statis/top/connected`
   *
   * @authorization-required
   *
   * Retrieve Top Connected Users Ranking data.
   */
  getList: getTopUserRanking,
};
