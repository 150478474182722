import { Button, ButtonGroup, Col } from "reactstrap";

type StatisticsButtonsProps = {
  onHandleCopy: () => void;
  onHandleExcelDownload: () => void;
  onHandlePdfDownload: () => void;
};

const StatisticsButtons = ({
  onHandleCopy,
  onHandleExcelDownload,
  onHandlePdfDownload,
}: StatisticsButtonsProps) => {
  return (
    <Col sm={12} className="d-flex justify-content-end mb-2">
      <ButtonGroup>
        <Button color="primary" onClick={onHandleCopy}>
          <i className="far fa-copy me-1"></i>
          Copy
        </Button>
        <Button color="primary" onClick={onHandleExcelDownload}>
          <i className="fas fa-file-excel me-1"></i>
          Excel
        </Button>
        <Button color="primary" onClick={onHandlePdfDownload}>
          <i className="fas fa-file-pdf me-1"></i>
          PDF
        </Button>
      </ButtonGroup>
    </Col>
  );
};

export default StatisticsButtons;
