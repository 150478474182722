import * as ApiBase from "@metamorp/api-base";
import { GetEditList, EditItem } from "./types";

const getEditList = (data: GetEditList) => {
  type Response = ApiBase.Types.ApiListResBase<EditItem>;
  return ApiBase.request<Response, GetEditList>({
    method: "POST",
    path: "/api/v1/project/edit/list",
    data: data,
  });
};

export default {
  /**
   * `POST /api/v1/project/edit/list`
   *
   * 프로젝트 파일 편집 요청 목록을 조회합니다.
   *
   * Retrieve a list of project file editing requests.
   */
  getList: getEditList,
};
