import { useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import RadioInput from "./components/RadioInput";
import { CustomNav } from "./components/styled";
import TargetsModal from "./components/TargetsModal";
import PageContainer from "../../components/Common/PageContainer";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import Selector from "../../components/Profile/Selector";
import { OptionProps } from "../AppEquipment";
import CustomCkEditor from "../EmailTemplate/components/CustomCkEditor";

const BREADCRUMB_ITEMS = [
  { title: "Send Email", link: "#" },
  { title: "Add", link: "#" },
];

const SendEmail = () => {
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();

  const [emailTempOptions, setEmailTempOptions] = useState<OptionProps[]>([]);
  const [emailContent, setEmailContent] = useState<
    Api.Response.EmailTemplateMain[]
  >([]);
  const [email, setEmail] = useState<Api.Request.SendEmail>({
    title: "",
    titleEn: "",
    content: "",
    contentEn: "",
    targetType: 1,
    targetEmails: "",
  });
  const [selectedTemplateIdx, setSelectedTemplateIdx] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("send");
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [isOpenTargets, setIsOpenTargets] = useState(false);

  useEffect(() => {
    const getAllEmailTemplates = async () => {
      const response = await Api.EmailTemplate.getAll();
      setEmailContent(response.data);
      const templates = response.data.map((item) => ({
        value: item.tempIdx,
        label: item.title,
      }));
      setEmailTempOptions(templates);

      if (templates.length) {
        const firstTemplate = response.data[0];
        setEmail((prev) => ({
          ...prev,
          content: firstTemplate.content,
          contentEn: firstTemplate.contentEn,
        }));
        setSelectedTemplateIdx(firstTemplate.tempIdx.toString());
      }
    };
    getAllEmailTemplates();
  }, []);

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type: string;
    };
  }) => {
    const { name, value, type } = e.target;
    const newValue = type === "radio" ? parseInt(value, 10) : value;
    if (name === "targetType" && newValue === 0) {
      setEmail((prevEmail) => ({
        ...prevEmail,
        [name]: newValue,
        targetEmails: "",
      }));
    } else if (name === "tempIdx") {
      const selectedTemplate = emailContent.find(
        (template) => template.tempIdx === parseInt(value, 10),
      );
      if (selectedTemplate) {
        setEmail((prevEmail) => ({
          ...prevEmail,
          content: selectedTemplate.content || "",
          contentEn: selectedTemplate.contentEn || "",
        }));
        setSelectedTemplateIdx(value);
      }
    } else {
      setEmail((prevEmail) => ({ ...prevEmail, [name]: newValue }));
    }
  };

  const handleSendEmail = async () => {
    if (
      !email.title ||
      !email.titleEn ||
      (email.targetType === 1 && !email.targetEmails)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      setIsOpen(false);
      return;
    }

    setIsLoading(true);
    try {
      const response = await Api.Emailing.send(email);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/email-history");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };
  const handleCancel = () => {
    navigate(`/email-history`);
  };

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Send Email">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <CustomNav>
                <h4 className="card-title">이메일 전송내용을 입력해주세요</h4>
                <FormButtonsTypes
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton
                  isSendingEmail
                />
              </CustomNav>
              <AvForm className="mt-4">
                <Row className="mb-3">
                  <Selector
                    label="템플릿"
                    id="tempIdx"
                    options={emailTempOptions}
                    value={selectedTemplateIdx}
                    onChange={handleChange}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="title"
                    label="이메일제목(한글)"
                    placeholder="이메일제목(한글)을 입력해주세요"
                    type="text"
                    value={email.title || ""}
                    onChange={handleChange}
                    initialErrorMessage="이메일제목(한글)을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInputTypes
                    id="titleEn"
                    label="이메일제목(영문)"
                    placeholder="이메일제목(영문)을 입력해주세요"
                    type="text"
                    value={email.titleEn || ""}
                    onChange={handleChange}
                    initialErrorMessage="이메일제목(영문)을 입력해주세요"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    isRequired
                  />
                </Row>
                <Row className="mb-3">
                  <Col xl="2" md="2">
                    <CustomLabel
                      htmlFor="radio"
                      title="전송대상"
                      isRequired
                      isFullWidth
                    />
                  </Col>
                  <Col
                    xl="10"
                    md="10"
                    className="d-flex align-items-center gap-2"
                  >
                    <RadioInput
                      value={0}
                      checked={email.targetType === 0}
                      id="radio1"
                      name="targetType"
                      label="전체회원"
                      onHandleChange={handleChange}
                    />
                    <RadioInput
                      value={1}
                      checked={email.targetType === 1}
                      id="radio2"
                      name="targetType"
                      label="메일입력"
                      onHandleChange={handleChange}
                    />
                    {email.targetType === 1 && (
                      <Button
                        type="button"
                        color="primary"
                        className="btn btn-sm"
                        onClick={() => setIsOpenTargets(true)}
                      >
                        대상찾기
                      </Button>
                    )}
                  </Col>
                </Row>
                {email.targetType === 1 && (
                  <Row className="mb-3">
                    <Col xl="2" md="2"></Col>
                    <Col xl="10" md="10">
                      <div>
                        <textarea
                          name="targetEmails"
                          id="targetEmails"
                          className="form-control"
                          rows={5}
                          value={email.targetEmails || ""}
                          onChange={handleChange}
                        ></textarea>
                        <small>(,)단위로 메일주소를 입력해주세요.</small>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="mb-3">
                  <CustomLabel
                    htmlFor="content"
                    title="내용(한글)"
                    isRequired
                  />
                  <Col md={10}>
                    <CustomCkEditor
                      id="content"
                      data={email.content || ""}
                      isFocused={isEditorFocused}
                      handleFocus={() => setIsEditorFocused(true)}
                      handleBlur={() => setIsEditorFocused(false)}
                      onChange={(content: string) =>
                        setEmail((prev) => ({ ...prev, content }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <CustomLabel
                    htmlFor="contentEn"
                    title="내용(영문)"
                    isRequired
                  />
                  <Col md={10}>
                    <CustomCkEditor
                      id="contentEn"
                      data={email.contentEn || ""}
                      isFocused={isEditorFocused}
                      handleFocus={() => setIsEditorFocused(true)}
                      handleBlur={() => setIsEditorFocused(false)}
                      onChange={(contentEn: string) =>
                        setEmail((prev) => ({ ...prev, contentEn }))
                      }
                    />
                  </Col>
                </Row>
                <FormButtonsTypes
                  openModal={openModal}
                  handleCancel={handleCancel}
                  hideDeleteButton
                  isSendingEmail
                />
                <ConfirmModalTypes
                  isLoading={isLoading}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  action={action}
                  handleSave={handleSendEmail}
                />
                <TargetsModal
                  setEmail={setEmail}
                  isOpen={isOpenTargets}
                  setIsOpen={setIsOpenTargets}
                />
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default SendEmail;
